const routes = [

    /*------ COMPONENTS --------------------------------------------------------------------*/

    /*
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },

     */
    { path: '/:slug', component: require('../components/firma/FirmaDetailDaten').default },
    { path: '/:slug/:seite', component: require('../components/firma/FirmaDetailDaten').default },
    { path: '/firmadetail/seite/:slug',name: 'firmaDetailSite', component: require('../components/firma/FirmaDetailDatenBackLink').default },
    { path: '/gutscheinformularvue/:id',name: 'gutscheinFormular', component: require('../components/GutscheinFormular').default },


    /* Falls nichts gefunden wird */
    // { path: '*', component: require('../components/NotFound.vue').default },


    /*------ VIEWS --------------------------------------------------------------------*/
/*
    { path: '/admin/seo/SeoContentDashboard', component:      require('../views/SeoContentDashboard').default },
    { path: '/admin/seo/AddOns/searchBrancheAddOns', component:      require('../components/seo/AddOns/searchBrancheAddOns.vue').default },
*/


    /* BACKEND */
    /* Dashboard */
    /*{ path: '/backend/home', component:      require('../views/backend/Dashboard').default },
    { path: '/backend/gold', component:      require('../views/backend/GoldDashboard').default },
    { path: '/backend/silver', component:      require('../views/backend/SilverDashboard').default },
    { path: '/backend/user', component:      require('../views/backend/UserDashboard').default },
    { path: '/backend/guest', component:      require('../views/backend/GuestDashboard').default },
    { path: '/backend/dashboard', component: require('../views/backend/Dashboard').default },

{ path: '/admin/seo/AddOns/searchBrancheAddOns', name: 'searchBrancheAddOns', component: () =>  import('../components/seo/AddOns/searchBrancheAddOns.vue') },

     { path: '/home', name: 'home', component: () =>  import('./components/backend/Dashboard.vue') },

     { path: '/profil/:id',
        name: 'Profil',
        component: () => import('./components/profil/backend/Profil.vue'),
        // profile/12/info
        children:[
            {
                path: 'info',
                name: 'Info',
                component: () => import('./components/profil/backend/Info'),
            }
        ]

    },



     */

    /* Users */


    /* Frontend */
]

export default routes;
