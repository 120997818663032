
<!--- PremiumKunden Helper von Dieter in parent vue einbauen parameter überbeben
<premium-kunden :banner="  premiumku  " ></premium-kunden>
-->

<template>
   <div v-if="showBezirkBanner === true" class="bg-blue p-2 mt-5">
        <div class="row">
            <div class="col-md-1">
            </div>
            <div v-for="werbebanner in bezirkBanner" class="col-md-2">
                <a :href="'' + werbebanner.Link" target="_blank" :title = werbebanner.Link ><img class=" w-100 p-1"
                                                                                                      :src="'/storage/partnergfx/' + werbebanner.Image"
                                                                                                      v-on:click="werbebannerMethode(werbebanner.KID, 3, werbebanner.BLID ,'Klicks')"
                                                                                                      :alt=  werbebanner.Link
                                                                                                      :title= werbebanner.Link
                ></a>
            </div>
            <div class="col-md-1 ">
            </div>
        </div>
   </div>
</template>
<script>

    export default {
        //name: 'banner-widget',
        //props: ['finde'],
       props: {
           mysuchmuster: {
               Type: String
           }
       },
        data() {
            return {
                bezirkBanner: this.bezirkBanner,
                showBezirkBanner: false,
                suchmuster: '',
                anzahlBZDS: 0
            };
        },

        computed: {
            showBanner: function() {
                if (this.bezirkBanner.length > 0) {
                    this.showBezirkBanner = true;
                    //console.log(this.$root.$children[0].$children[2].$children[0].showBundeslandBanner);
                    //this.$root.$children[0].$children[2].$children[0].showBundeslandBanner = false;
                    this.$root.$children[0].$children[2].$children[1].showBundeslandBanner = false;

                }
            }
        },

        methods: {
            getBezirkBanner(bezirk){
            axios.get('/banner/getBezirkBanner/' + bezirk, {})
                .then(response => {
                    this.bezirkBanner = response.data
                    this.anzahlBZDS = this.bezirkBanner.length;
                    if(this.anzahlBZDS == 0){
                        this.showBezirkBanner = false;
                    }
                    Fire.$emit('anzahlBZDSEvent',this.anzahlBZDS);
                    this.showBanner;
                    //console.log(this.werbebannerNational);
                })
            },

            werbebannerMethode: function(KID,typID,flag,typ) {
            var ADID;
            var bannerKlicks;
            axios.get('/banner/getADID/KIDBLID/' + KID + '/' + flag , {})
                .then(response => {
                    this.ADID = response.data

                    axios.get('/statistik/firma/setBannerHitsKlicks/' + this.ADID + '/' + typID + '/' + flag + '/' + typ, {})
                        .then(response => {
                            this.bannerKlicks = response.data
                           // console.log(this.bannerKlicks);
                        });
                });
            },
        },

        created(){

            this.suchmuster = this.mysuchmuster;
            console.log('Beirk',this.suchmuster);
            //this.suchmuster = this.$root.$children[0].$refs['mainformWidget'].$refs.sf1.suchmuster;
            Fire.$on('setSuchmuster',(data) => {
                this.suchmuster = data;
                //this.suchmuster = this.$root.$children[0].$children[0].$children[1].suchmuster;
                this.getBezirkBanner(this.suchmuster);
            })

            this.getBezirkBanner(this.suchmuster);

        },

        mounted() {
            //console.log(this.getWerbebannerNational());
            console.log('bin in BezirkBannerWidget')
        }
    }
</script>
