<template>
    <div class="form-group-lg col-sm-12 col-md-5 mb-0 pb-0 autocomplete">

        <input ref="suchmuster2" id="suchmuster2"  type="text" v-model="suchmuster2"
               @click="clickFeld2()"
               @keyup="inputChanged"
               @keydown.down="onArrow"
               @keydown.up="onArrow"
               @keyup.enter="nextPlease()"
               class="form-control form-control-lg"
               placeholder="Firmenname"
        />

        <i class="deleteInhalt pl-0 fas fa-times-circle text-muted" @click="reset()"></i>
        <span id="suchhilfe" style="font-size: 0.6rem;" class="">neue Suche X klicken</span>
        <div v-if="isOpen" class="automplete-wrapper">
            <div v-for="(user, i) in filteredFirmenList" :key="i" class="autocomplete-results list-group-item list-group-item-action  py-1" v-show="isOpen" :class="{ 'is-active': i === arrowCounter }">
                <div style="cursor: pointer"   @click="setResult(user.name),setCursor(),setMainWidgetSuchmuster()">{{ user.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Sf2",
    data() {
        return {

            firmenList: [],
            suchmuster2: '',
            arrowCounter: -1,
            isOpen: false,
            filteredFirmenList: []
        }
    },

    methods: {

        nextPlease: function () {


            Fire.$emit('setSf2', 1);
            Fire.$emit('setSuchmuster2Wertevent', this.suchmuster2);
            document.getElementById('startformsendenbutton').focus();
        },

        //löscht beim wechsel auf anderes eingabe feld den Wert vom alten
        resetMyOtherField2(){
            //Fire.$emit('onFocusSf1', '');
            Fire.$emit('setSuchmuster1Wertevent', '');
        },

        setCursor(){

            this.$nextTick(() => this.$refs.suchmuster2.focus())
            this.suchmuster2.trim();
        },
        clickFeld2(){
            Fire.$emit('absendenevent','nein');
            Fire.$emit('setSf1', 0);
            Fire.$emit('setSf2', 0);

            Fire.$emit('is-openSf1', false);
            Fire.$emit('setSuchmuster1Wertevent', '');
            Fire.$emit('setFilteredBranchenList', []);
        },

        setMainWidgetSuchmuster(){


            if(this.suchmuster2.length > 0) {
                Fire.$emit('setSuchmuster2Wertevent', this.suchmuster2.trim());

                this.filteredFirmenList = [];
                this.isOpen = false;

                Fire.$emit('setSf2', 0);
            }
            else {
                this.isOpen = false;
            }

        },



        pressEnter(){
            if(this.suchmuster2.length > 0){
                this.setSuchmuster(); // optimiert den Link
                Fire.$emit('setSf2', 1)
                Fire.$emit('setSf1', 0)


            }
        },

        setSuchmuster: function () {
            Fire.$emit('absendenevent','nein');
            let neuesSuchmuster = '';
            neuesSuchmuster = this.suchmuster2.replace("firma","");
            Fire.$emit('setSuchmuster', neuesSuchmuster.trim());
        },


        // autocomplete
        loadAlleFirmen(){
            axios.get('/admin/seo/get/allefirmen')
                .then((response) => {
                    this.firmenList = response.data
                })
                .catch(() => {
                })
        },


        setResult(text) {
            Fire.$emit('absendenevent','nein');
            Fire.$emit('setSf2', 0);
            this.suchmuster2 = text
            Fire.$emit('setSuchmuster2Wertevent', this.suchmuster2.trimLeft());

        },

        onArrow(event) {
            if (this.filteredFirmenList.length > 0) {
                this.arrowCounter = event.code == "ArrowDown" ? ++this.arrowCounter : --this.arrowCounter;
                if (this.arrowCounter >= this.filteredFirmenList.length)
                    this.arrowCounter = (this.arrowCounter) % this.filteredFirmenList.length;
                else if (this.arrowCounter < 0)
                    this.arrowCounter = this.filteredFirmenList.length + this.arrowCounter;
                this.setResult(this.filteredFirmenList[this.arrowCounter].name);
            }

        },
        inputChanged(event) {
            if (event.code == "ArrowUp" || event.code == "ArrowDown")
                return;

            this.filteredFirmenList = [];

            if (event.code == "Enter")

                return;

            var filtered = this.firmenList.filter((user) => {
                return user.name.match(this.suchmuster2.toLowerCase().trimLeft())
            });

            this.isOpen = true
            this.filteredFirmenList.push(...filtered)

            Fire.$emit('setSuchmuster2Wertevent', this.suchmuster2.trimLeft());


            //console.log(this.filteredFirmenList)
        },

        reset() {
            this.suchmuster2 = '';
            Fire.$emit('setSf1', 0);
            Fire.$emit('setSuchmuster2Wertevent', '');
            this.isOpen = false;
            this.filteredFirmenList = [];
            this.$nextTick(() => this.$refs.suchmuster2.focus())
        },

    },

    mounted() {


        Fire.$on('is-openSf2',(value) => {
            this.isOpen = value;
        });
        Fire.$on('setFilteredFirmenList',(value) => {
            this.filteredFirmenList = value;
        });
        Fire.$on('setSuchmuster2Wertevent',(value) => {
            this.suchmuster2 = value;
        });


        this.loadAlleFirmen();



    }

}



</script>

<style scoped>



.deleteInhalt {
    position: relative;
    top: -30px;
    left: -10px;
    float: right;
    cursor: pointer !important;
}

.automplete-wrapper{
    position:relative;
    top: -1.5rem !important;
    overflow:hidden;
    max-height: 22.5rem !important;
}

/*
.autocomplete {
    position: relative;

}

.autocomplete-results {
    padding: 0;
    margin: 0;

    border: 1px solid #eeeeee;

    overflow: auto;
    width: 100%;
}

.autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
    background: red;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;

}
*/
.is-active {
    background-color: #dedede;
}

</style>
