<template>
    <div class="container-fluid mb-5">
        <div class="row">
            <div class="col-12 p-0">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <strong><div style="cursor: pointer" @click="showSuchfeld"><i class="fas fa-search fa-lg"></i> Suche</div></strong>
                        </li>
                        <li class="breadcrumb-item  ml-auto">
                            <a href="/">Home</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span style="cursor: pointer;" @click="showSuchtrefferEmit()"><span class="text-green">Suchtreffer</span></span>
                        </li>
                        <li class="breadcrumb-item active">
                            Visitenkarte
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 position-relative p-3 bg-blue text-sm">
                <div class="ribbon-wrapper ribbon-lg">
                    <div v-if="firma.vorteilscard" class="ribbon bg-info">
                        {{firma.vorteilscard}}% Rabatt
                    </div>
                </div>
                <div class="row text-center border-bottom pb-3">
                    <div v-if="firma.cover_image" class="col-md-2">
                        <a :href="'' + firma.web | urlFormater " v-on:click="zeigeWeblinks, setWebseiteKlicks('Weblinks',firma.id)" :title="firma.web" target="_blank"><img class="border p-1" :title="firma.name" :alt="firma.name"
                                                                                                                                                                            :src="'/storage/logos/' + firma.cover_image" width="'120'" height="'120'"/></a>
                    </div>
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-12">
                                <address>
                                    <strong><h1 class="">{{firma.name}}</h1></strong><br />
                                    {{firma.rStrasse}}<br/>
                                    {{firma.rPlz}} {{firma.rOrt}}<br/>
                                </address>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="" role="group" aria-label="Module">
                                    <div style="cursor: pointer" class="btn btn-outline-light mr-2 mt-2" role="button" :href="'#telefonnummerziel'" v-if="zeigeTEl == false"
                                         @click="zeigeTelefonNr,setHitKlicks2('telclick',firma.id,firma.slug,firma.tel)"><i
                                        class="fas fa-phone-alt "></i> Telefon Nummer</div>
                                    <a  class="btn btn-outline-light text-sm mr-2 mt-2" role="button" :href="'tel:' + firma.tel " v-if="zeigeTEl"><i class="fas fa-phone-alt"></i>&nbsp;{{firma.tel}}</a>
                                    <a class="btn btn-outline-light text-sm mr-2 mt-2" :href="'mailto:' + firma.email"><i class="fas fa-envelope"></i> Email senden</a>

                                    <a v-if="firma.web" class="btn btn-outline-light mr-2 mt-2" role="button" :href="'' + firma.web | urlFormater " v-on:click="zeigeWeblinks, setWebseiteKlicks('Weblinks',firma.id)" :title="firma.web" target="_blank">
                                        <i class="fas fa-globe-americas"></i> Zur Homepage</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="wrapper-detail" class="text-muted bg-white mt-3 p-2 text-center">
                    <div class="row ">
                        <div id="branchen" class="col-md-12 d-inline ">
                            <h6>Auch zu finden unter:
                                <div v-for="item in firma.diebranchen" :key="item.id" class="d-inline-block"><span
                                    v-html="item.name + ',&nbsp;'"></span></div>
                            </h6>
                        </div>
                        <div id="visitenkarte" class="w-100 py-3 mx-2 mt-2 border-top">
                            <p class="p-2" v-html="firma.visitenkarte"></p>
                            <p class="text-center">
                                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#kontaktFormModal">
                                    <i class="far fa-envelope fa-lg"></i> Zum Kontaktformular
                                </button>
                            </p>
                            <p class="border-bottom mt-2"> </p>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center">

                            <section class="mapbox hidden-xs">
                               <!-- <a target="_blank" :href="'https://www.google.com/maps/place/' + firma.strasse + ',' + firma.plz + '-' + firma.Ort + '/@' + firma.geo_lat + ',' + firma.geo_lng"> -->
                                <a target="_blank" :href="'https://www.google.com/maps/search/' + firma.rStrasse + ',' + firma.rPlz + ' ' + firma.rOrt + '/@' + firma.geo_lat + ',' + firma.geo_lng">
                                    <img
                                src="https://www.hausbaufuehrer.at/images/gmaps.png" alt="Google Maps"
                                style="width: 40px;"> zur Kartenansicht auf Google Maps </a></section>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Galerie -->

            <div class="list-group mt-3 border col-sm-12 col-md-12 m-0 p-0 mt-1 mb-5" v-if="bilderVorhanden">
                <div >
                    <div href="#" class="list-group-item list-group-item-action active bg-blue">
                        Bildergalerie
                    </div>
                    <div class="p-3 text-left">

                        <!-- bilder reinladen -->


                        <div class="row">
                            <div v-for="(bilder, index) in firma.vkBilder" class="col">

                                <a data-toggle="modal" :data-target="'#galerieModal' + index" href=""><img :src="'/storage/vcards/' + bilder" class="img-fluid" :title="firma.name"
                                                                                                           :alt="firma.name"></a>


                                <!-- Modal für Galerie -->
                                <div class="modal fade" :id="'galerieModal' + index" tabindex="-1" role="dialog" aria-labelledby="galerieModalTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="galerieModalTitle">{{firma.name}}</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body text-center">
                                                <img :src="'/storage/vcards/' + bilder" class="img-fluid" :title="firma.name"
                                                     :alt="firma.name">
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Schließen</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Modal für Galerie Ende -->

                            </div>

                        </div>


                    </div>

                </div>
            </div>



            <div v-if="firma.gutscheinHeader !== null && firma.gutscheinHeader.length > 0" class="list-group mt-3 border col-sm-12 col-md-12 m-0 p-0 mt-1">

                <div href="#" class="list-group-item list-group-item-action active bg-blue">
                    Gutschein
                </div>
                <div class="card-body">
                    <h5 class="" v-html="firma.gutscheinHeader"></h5>


                    <div class="text-sm">
                        <span v-html="firma.gutscheinleistung"></span>

                        <!-- nicht gebraucht
                        <router-link :to="'/gutscheinformularvue/' + firma.id" class="card-link" >Gutschein jetzt Anfordern <i
                            class="fas fa-angle-double-right"></i></router-link>
                        -->
                        <a :href="'/gutscheinformular/' + firma.id" class="card-link" >Gutschein jetzt Anfordern<i
                            class="fas fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>


            <div v-if="firma.vorteilscard" class="list-group mt-3 border col-sm-12 col-md-12 m-0 p-0 mt-1">
                <div href="#" class="list-group-item list-group-item-action active bg-blue">
                    Vorteilscard
                </div>
                <h5 class="bg-gray white p-2 pl-4">{{firma.vorteilscard}}
                    <i class="fas fa-percent"></i> Rabatt
                    mit der Vorteilskarte</h5><br />
                <a href="/vorteilskarte" class="text-left p-3 pl-4">Keine Vorteilskarte?<br />Hier GRATIS bestellen <i class="fas fa-angle-double-right"></i></a><br />
            </div>

            <!-- Galerie End-->

            <div v-if="seoFirmaAnzeigen" id="seo-content" class="w-100">

                <h1 class="text-right  border-bottom mb-2 mt-5"></h1>
                <h1 style="text-align:right">{{ seoContent.uschrift }}</h1>
                <p v-html="seoContent.beschreibung"></p>
            </div>
        </div>

        <!-- Modal für Email -->
        <!-- Modal -->
        <form
            id="app"
            @submit="checkForm"
            action="/admin/firma/kontaktformular/vue/send"
            method="post"
        >
            <div class="modal fade" id="kontaktFormModal" tabindex="-1" role="dialog" aria-labelledby="kontaktFormModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="kontaktFormModalLabel">Kontaktformular</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="_token" :value="csrf">
                            <input type="hidden" name="id" :value="firma.id"><br>
                            <p>
                            <p v-if="errors.length">
                                <b>Bitte füllen Sie die unten angeführten Textfelder aus:</b>
                            <ul>
                                <li v-for="error in errors" class="text-red">{{ error }}</li>
                            </ul>

                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputVorname">Vorname</label>

                                    <input
                                        id="inputVorname"
                                        v-model="vorname"
                                        type="text"
                                        name="vorname"
                                        class="form-control"
                                        placeholder="Vorname"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputNachname">Nachname</label>
                                    <input
                                        id="inputNachname"
                                        v-model="nachname"
                                        type="text"
                                        name="nachname"
                                        class="form-control"
                                        placeholder="Nachname"
                                    >
                                </div>
                            </div>



                            <div class="form-group">
                                <label for="inputStrasse">Strasse</label>
                                <input
                                    id="inputStrasse"
                                    v-model="strasse"
                                    type="text"
                                    name="strasse"
                                    class="form-control"
                                    placeholder="Strasse"
                                >
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputPlz">PLZ</label>
                                    <input
                                        id="inputPlz"
                                        v-model="plz"
                                        type="text"
                                        name="plz"
                                        class="form-control"
                                        placeholder="Plz"
                                    >
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="inputOrt">Ort</label>
                                    <input
                                        id="inputOrt"
                                        v-model="ort"
                                        type="text"
                                        name="ort"
                                        class="form-control"
                                        placeholder="Ort"
                                    >
                                </div>

                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputEmail">Email</label>
                                    <input
                                        id="inputEmail"
                                        v-model="email"
                                        type="text"
                                        name="email"
                                        class="form-control"
                                        placeholder="Email"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputTelefon">Telefon</label>
                                    <input
                                        id="inputTelefon"
                                        v-model="telefon"
                                        type="text"
                                        name="telefon"
                                        class="form-control"
                                        placeholder="Telefon"
                                    >
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="inputTelefon">Ort des Bauvorhabens</label>
                                    <input
                                        id="inputOrtbauvorhaben"
                                        v-model="ortbauvorhaben"
                                        type="text"
                                        name="ortbauvorhaben"
                                        class="form-control"
                                        placeholder="Ort Bauvorhaben"
                                    >
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="betreff">Grund der Anfrage, bitte auswählen.</label>
                                    <select id="betreff" name="betreff"  v-model="betreff" class="form-control">
                                        <option value hidden></option>
                                        <option value="bitte um Angebotslegung">bitte um Angebotslegung</option>
                                        <option value="allgemeine Anfrage">allgemeine Anfrage</option>
                                        <option value="bitte um Terminvereinbarung">bitte um Terminvereinbarung</option>
                                        <option value="bitte um Zusendung von Infomaterial">bitte um Zusendung von Infomaterial</option>
                                    </select>
                                    <label class="form-check-label" for="betreff">
                                        <small>Details bitte im Beschreibungsfeld eintragen.</small>
                                    </label>
                                </div>

                            </div>



                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="beschreibung">Beschreibung</label>
                                    <textarea class="form-control rounded-0 " name="beschreibung" v-model="beschreibung" id="beschreibung" rows="5"></textarea>
                                </div>

                            </div>
                            <div :class="{mx_recaptcha_failed: !recaptcha}">
                            <p>
                                <vue-recaptcha
                                    sitekey="6LdijMEaAAAAABT-_gLEAnTrZsTAUyXy0sbJmHnc"
                                    @verify="mxVerify"
                                ></vue-recaptcha>

                            </p>
                            </div>
                            <p>
                                <input type="checkbox" class="float-left" name="vorteilscard" id="vorteilscard" v-model="vorteilscard">
                                <label class="ml-4" style="margin-top: -18px;" for="vorteilscard">Ja, ich will die Hausbauführer Vorteilskarte bestellen
                                    und damit viele Vergünstigungen bei Partnerfirmen erhalten</label>
                            </p>

                            <p>
                                <input type="checkbox" id="einverstanden" value="einverstanden" v-model="einverstanden">
                                <label class="ml-4" style="margin-top: -25px;" for="einverstanden">

                                    Ja, ich habe die <a href="#">Datenschutzerklärung</a> zur Kenntnis genommen und bin damit einverstanden, dass die von mir angegebenen Daten elektronisch erhoben und gespeichert werden. Meine Daten werden dabei nur streng zweckgebunden zur Bearbeitung und Beantwortung meiner Anfrage benutzt. Mit dem Absenden des Kontaktformulars erkläre ich mich mit der Verarbeitung einverstanden.
                                </label>
                            </p>

                            <p>
                                <input name="newsletter" type="checkbox" id="newsletter" value="ja">
                                <label class="ml-4" style="margin-top: -25px;" for="newsletter">

                                    <strong>Newsletter</strong>, informieren Sie mich über Neuigkeiten und Partnerprogramme
                                </label>
                            </p>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Schließen</button>
                            <button class="btn btn-primary" type="submit" value="Email senden">Email senden</button>
                            <input class="d-none btn btn-primary" type="submit" value="Email senden">
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- Modal für Email Ende -->






    </div>
</template>

<script>

import VueRecaptcha from 'vue-recaptcha';


export default {
    name: 'FirmaDetailDatenBacklink',
    components: { VueRecaptcha },
    //props: ['vkBilder'],
    data() {
        return {
            seoFirmaAnzeigen: false,
            seoContent: {},

            success: false,
            error: false,
            output: '',

            slug: this.slug,
            firma: [],
            zeigeTEl: false,
            zeigeWeblinks: false,
            bilderVorhanden: false,

            // für Formular
            vorname: null,
            nachname: null,
            strasse: null,
            plz: null,
            ort: null,
            email: null,
            telefon: null,
            betreff:null,
            ortbauvorhaben: null,
            beschreibung: null,
            einverstanden: false,
            vorteilscard: '',
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            errors: [],
            zeigeGutschein: false,
            zeigeSuchfeld: false,

            recaptcha: null


        }
    },
    methods: {

        mxVerify( response){
            //console.log( response )
            this.recaptcha = response
        },
        showStartseiteSeo(status){
            Fire.$emit('show-startseite-content',status);
        },


        getSeoContent(slug){
            var slugArr = slug.split("-");
            var id = slugArr[slugArr.length - 1];
            let currObj = this;
            axios.get('/seo/firma/get/firmadetail/content/' + id )
                .then((response) => {

                    currObj.seoContent = response.data;
                    if(Object.keys(currObj.seoContent).length > 0){
                        this.seoFirmaAnzeigen = true
                    }

                });

            //$seoContent = DB::table('seoFirma')->where('firma_id',$id)->first(['metaTitle as uschrift', 'content1 as beschreibung']);

        },


        showSuchtrefferEmit(){
            const daten = [];
            daten['showList'] = true;
            daten['showDetailSeite']  = false;
            Fire.$emit('showSuchtrefferEmit',daten);
            var root = location.protocol + '//' + location.host + '/';
            //this.$router.push(root);
            this.$router.go(-2);
        },

        goBack(){
            //this.$router.push({name: 'HomePage'})
            window.alert('test');
            //this.$router.push({name: 'mytest1'});
        },

        zeigeBilder(data){
            if(data['vkBilder'].length > 0 ) {
                return true;
            }
        },

        checkForm: function (e) {
            if (this.vorname
                && this.nachname
                && this.vorname
                && this.strasse
                && this.plz
                && this.ort
                && this.telefon
                && this.email
                && this.betreff
                && this.beschreibung
                && this.ortbauvorhaben

                && this.einverstanden

                && this.recaptcha
            ) {
                console.log('wurde gesendet')
                return true;
            }

            this.errors = [];

            if (!this.recaptcha) {
                this.errors.push('Ich bin kein Roboter, Klicken.');
            }

            if (!this.vorname) {
                this.errors.push('Vorname erforderlich.');
            }
            if (!this.nachname) {
                this.errors.push('Nachname erforderlich.');
            }
            if (!this.strasse) {
                this.errors.push('Strasse erforderlich.');
            }
            if (!this.plz) {
                this.errors.push('Plz erforderlich.');
            }
            if (!this.ort) {
                this.errors.push('Ort erforderlich.');
            }
            if (!this.email) {
                this.errors.push('Email erforderlich.');
            }
            if (!this.telefon) {
                this.errors.push('Telefon erforderlich.');
            }
            if (!this.betreff) {
                this.errors.push('Betreff erforderlich.');
            }
            if (!this.ortbauvorhaben) {
                this.errors.push('Ort Bauvorhaben erforderlich.');
            }
            if (!this.beschreibung) {
                this.errors.push('Beschreibung erforderlich.');
            }
            if (!this.einverstanden) {
                this.errors.push('Datenschutzerklärung akzeptieren!');
            }
            e.preventDefault();
        },
        // statistik
        /*
         setHitWeblink(type,slug){
             axios.get('/statistik/firma/setKlicks/'+ type + '/' + slug).then('OK');
         },
         */

        setHitKlicks(type, slug) {
            axios.get('/statistik/firma/setKlicks/' + type + '/' + slug).then('OK');
        },

        // nur das geht am Server in Wien
        setHitKlicks2Vue(type,slug,tel){
            let currentObj = this;
            // window.alert('type ' + type + ' und id ' + id + ' und slug ' + slug);
            //console.log('type ' + type + ' und id ' + id + ' und slug ' + slug);
            var root = location.protocol + '//' + location.host + '/';
            // axios.get('/statistik/firma/setKlicks/'+ type + '/' + slug).then('OK');

            axios.get('/statistik/firma/setKlickszweiVue/'+ type + '/' + slug).then(({response}) => (
                currentObj.message = response.message
            )).catch(function (error) {
               // currentObj.error = error
            });
            window.location.href= "tel:" + tel
        },



        setGSViewsCounter(type, slug) {
            axios.get('/statistik/firma/setGSViews/' + type + '/' + slug).then('OK');
        },

        setWebseiteKlicks(type, slug) {
            axios.get('/statistik/firma/setWeblinks/' + type + '/' + slug).then('OK');
        },

        setZeigeGutschein(type,slug){
            if(this.zeigeGutschein == true) {
                this.setGSViewsCounter(type, slug)
            }
        },

        //show daten
        getFirma(slug) {
            axios.get("/firma/getFirmDaten/" + slug).then(({data}) => (
                this.firma = data,
                    this.bilderVorhanden = this.zeigeBilder(data),
                    this.setGutscheinLink(this.firma.id), // 2920 muss treffer bringen // 6269 keine Treffer
                    //this.zeigeGutschein = (data['gutscheinHeader'] !== null &&  data['gutscheinHeader'].length > 0) ? true : false,
                    this.setZeigeGutschein('GSViews',this.slug)
            ));
        },


        setGutscheinLink(id){
            let curObj = this;
            let status = [];
            axios.get("/gutschein/vorhanden/" + id).then(({data}) => (
                curObj.zeigeGutschein = data.gutscheinvorhanden,
                    console.log('der status ist ', curObj.zeigeGutschein)

            ));
        },


        //zeige Suchformular
        showSuchfeld(){
            //this.$emit('changeTitle',true);
            Fire.$emit('showsuchmaskeevent',true);
            //Fire.$emit('zeigeSuchform',true);
            //this.zeigeSuchfeld = true;

           // const daten = [];
           // daten['showSuchmaske'] = false;
           // Fire.$emit('showSuchmaskeEmit',daten);

           // var root = location.protocol + '//' + location.host + '/';
           // window.location.href = root;

        }

    },

    filters: {
        urlFormater: function (value) {
            if (value.length > 0){

                if(value.substring(0, 8) == 'https://'){
                    console.log(value.substring(0, 8));
                    return value;
                }
                else if(value.substring(0, 7) == 'http://'){
                    console.log(value.substring(0, 7));
                    return value;
                }
                else{

                    return '#';
                }
            }
            else{
                return '';
            }
        }
    },


    computed: {
        zeigeTelefonNr() {
            this.zeigeTEl = true;
        },


    },

    zeigeWeblinks() {
        this.zeigeWeblinks = true;
    },


    created() {
        // this.slug =  this.$parent.$attrs.slug;
        //console.log('firmaDetailDaten',this.$parent.$attrs.slug);

    },

    beforeRouteLeave (to, from, next) {
        const answer = window.alert('Benutzen Sie den grünen "Suchtreffer" link.')
        next(false)
        /*
        if (answer) {
            next()
        } else {
            next(false)
        }
         */
    },

    mounted() {

        Fire.$emit('showsuchmaskeevent',false);

        setTimeout(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }, 500);

        this.slug = this.$route.params.slug;
        this.getFirma(this.slug);

       // this.getSeoContent();
        //console.log('firmaDetailDaten',this.$parent.$attrs.slug);

        this.showStartseiteSeo(false);
        this.getSeoContent(this.$route.params.slug);

    }
}
</script>
<style>
div.modal-footer input.btn.btn-primary{
    background: green;
    color:white;
}

div.modal-footer button.btn.btn-danger {
    background: red;
    color:white;
}

.btn{
    padding: 2px 4px;
    padding: 0.1rem 0.4rem;

}

.bg-blue {
    background-color:
        #0d4295 !important;
}





</style>
