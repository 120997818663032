
<!--- PremiumKunden Helper von Dieter in parent vue einbauen parameter überbeben
<premium-kunden :banner="  premiumku  " ></premium-kunden>
-->

<template>
   <div v-if="showBundeslandBanner === true" class="bg-blue p-2 mt-5">
        <div class="row">
            <div class="col-md-1">
            </div>
            <div v-for="werbebanner in bundesLandBanner" class="col-md-2">
                <a :href="'' + werbebanner.Link" target="_blank" :title = werbebanner.Link ><img class=" w-100 p-1"
                                                                                                      :src="'/storage/partnergfx/' + werbebanner.Image"
                                                                                                      v-on:click="werbebannerMethode(werbebanner.KID, 3, werbebanner.BLID ,'Klicks')"
                                                                                                      :alt=  werbebanner.Link
                                                                                                      :title= werbebanner.Link
                ></a>
            </div>
            <div class="col-md-1 ">
            </div>
        </div>
   </div>
</template>

<script>

    export default {
        //name: 'banner-widget',
        //props: ['finde'],
        props:{
            mysuchmuster: {
                Type: String,
            }
        },
        data() {
            return {
                bundesLandBanner: [],
                showBundeslandBanner: false,
                suchmuster: '',
                anzahlBLDS: 0,

            };
        },

        computed: {
         showBanner() {
             if (this.bundesLandBanner.length > 0) {
                 this.showBundeslandBanner = true;
                 //this.$root.$children[0].$children[2].$children[1].showBezirkBanner = false;
                 this.$root.$children[0].$children[2].$children[2].showBezirkBanner = false;
             }
         }
        },

        methods: {
            getBundeslandBanner(BLID){

                        axios.get('/banner/getBundesLandBanner/' + BLID, {})
                            .then(response => {
                                this.bundesLandBanner = response.data
                                this.anzahlBLDS = this.bundesLandBanner.length;
                                if(this.anzahlBLDS == 0){
                                    this.showBundeslandBanner = false;
                                }
                                Fire.$emit('anzahlBLDSEvent',this.anzahlBLDS);
                                //console.log(this.werbebannerNational);
                                this.showBanner;
                            })

            },

            werbebannerMethode: function(KID,typID,flag,typ) {
                var ADID;
                var bannerKlicks;
                axios.get('/banner/getADID/KIDBLID/' + KID + '/' + flag, {})
                    .then(response => {
                        this.ADID = response.data
                        axios.get('/statistik/firma/setBannerHitsKlicks/' + this.ADID + '/' + typID + '/' + flag + '/' + typ, {})
                            .then(response => {
                                this.bannerKlicks = response.data
                               // console.log(this.bannerKlicks);
                            });
                    });
            },
        },

        created(){
            this.suchmuster = this.mysuchmuster;

            //this.suchmuster = this.$root.$children[0].$refs['mainformWidget'].$refs.sf1.suchmuster;
            Fire.$on('setSuchmuster',(data) => {
                this.suchmuster = data;
                //this.suchmuster = this.$root.$children[0].$children[0].$children[1].suchmuster;
                this.getBundeslandBanner(this.suchmuster);
            })

            this.getBundeslandBanner(this.suchmuster);

        },

        mounted() {

            //console.log(this.getWerbebannerNational());
            console.log('bin in BundeslandBannerWidget')
        }
    }
</script>
