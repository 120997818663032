<template>
<div>
    <div class="row text-muted bg-gray-light p-3 mb-5 mx-1">
        <div class="col-md-9">
            <h1>Nutzen Sie Ihren Vorteil<br />mit dem Gutschein der Firma {{firma.name}}<br />
                <small class="text-blue"> Mit diesem Gutschein der Firma {{firma.name}} erhalten Sie folgende Leistung:</small>
            </h1>
            <p>
                <strong class="text-green" v-html="gutschein.GSLeistung"></strong>

            </p>
            <p>
                Nach Ausfüllen Ihres Namens, können Sie Ihren persönlichen Gutschein ausdrucken. Dieser Gutschein gilt nur für die Firma "{{firma.name}}". Änderungen sind vorbehalten. Es besteht kein Anspruch auf Erhalt der Leistungen. Es gelten die Bedingungen der Firma {{firma.name}}. Die Firma Joachim Sifkovits - www.hausbaufuehrer.at kann nicht haftbar gemacht werden für Verweigerung des Gutscheins oder Änderung der Leistung.
            </p>


        </div> <div class="col-md-3"><img src="/storage/systembilder/hbf_gutschein_icon_164x107.gif" alt="Card image" class="card-img"></div>
    </div>
    <div class="row">
        <div class="col-md-6">

            <div class="card">
                <h5 class="card-header">Gutschein Formular</h5>
                <div class="card-body">
                    <h5 class="card-title">Gutschein für</h5>
                    <div class="card-text">
                        <form>
                            <div class="form-group">
                                <label class="d-none" for="inputVorname">Vorname</label>
                                <input type="text" class="form-control" v-model="vorname" @change="hasData" id="inputVorname" placeholder="Vorname">
                            </div>
                            <div class="form-group">

                                <input type="text" v-model="nachname" class="form-control" id="inputNachname" @change="hasData" placeholder="Nachname">
                            </div>
                            <div class="form-group">

                                <input type="email" @change="hasData" v-model="email" class="form-control" id="inputEmail" aria-describedby="emailHelp" placeholder="Email">
                                <small id="emailHelp" class="d-none form-text text-muted">Die Emaladresse wird nicht weiterverwendet</small>
                            </div>
                            <div class="form-group form-check">
                                <input @click="setAgb(true)" type="checkbox" v-model="agb" class="form-check-input"  id="agb">
                                <label class="form-check-label" for="agb">
                                    Ich bin damit einverstanden, dass meine Daten für Werbeaktivitäten von hausbauführer.at verwendet werden dürfen und mir aktuelle Informationen als Newsletter zugesandt werden können.
                                    <br /><br />Meine Daten werden dabei nicht an Dritte weitergegeben.
                                </label>
                            </div>
                            <button type="submit" class="d-none btn btn-primary">Gutschein Drucken</button>
                        </form>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card">
                <h5 class="card-header">Gutschein von </h5>
                <div class="card-body">
                    <h5 class="card-title"></h5>
                    <div class="card-text">
                        <div class="row">
                        <div class="col-md-4">
                            <img :src="'/storage/logos/' + firma.cover_image" />
                        </div>
                        <div class="col-md-8">
                            <h1>{{firma.name}}</h1>
                            <p>
                                {{firma.strasse}}<br />
                                {{firma.plz}}/{{firma.Ort}}
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <h5 class="card-header">Ausgestellt auf </h5>
                <div class="card-body">
                    <h5 class="card-title"></h5>
                    <div class="card-text">
                        <div class="row">
                            <div class="col-md-4">
                                <img class="circle" src="/storage/systembilder/hbf_gutschein_icon_164x107.gif" />
                            </div>
                            <div class="col-md-8 pl-5 pt-2">
                                <p>
                                    {{vorname}} {{nachname}}<br />
                                    <strong class="text-green" v-html="gutschein.GSLeistung"></strong><br />
                                    am {{moment().locale('de').format('ll')}}
                                </p>
                                <p>

                                    <a href="#" @click="downloadPDF(
                                        vorname +'-'+ nachname +'_gutschein.pdf',
                                        '/storage/logos/' + firma.cover_image,
                                         firma,
                                         vorname,
                                         nachname,
                                         email,
                                         datum,
                                         gutschein

                                    )" v-if="agb" class="btn btn-primary">Gutschein Drucken
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
</template>

<script>
var moment = require('moment');
import jsPDF from "jspdf";
import 'jspdf-autotable';

// Default export is a4 paper, portrait, using millimeters for units



import _ from "lodash";
//import myLocationsCheckbox from "../../helper/MyLocationsCheckbox";




export default {
    components:{
        //myLocationsCheckbox
    },
    props: {
        firmaProps: Object,
        gutscheinProps: Object,
        // title: String,
        // likes: Number,
        // isPublished: Boolean,
        // commentIds: Array,
        // author: Object,
        // callback: Function,
        // contactsPromise: Promise // or any other constructor
    },
    name: "GutscheinFormular",
    data () {
        return {
        moment: moment,
        datum:  moment().locale('de').format('ll'),
        firma : this.firmaProps,
        gutschein: this.gutscheinProps,

        vorname: '',
        nachname: '',
        email: '',
        agb: false,

        }
    },

    computed: {

    },


    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },

        tolowercase: function (value) {
            if (!value) return ''
            value = value.toLowerCase();
            return value
        },


        arraytostring: function (value) {
            var str = value;
            var res = str.split("#");
            var result = res.join(' | ');
            // console.log(result);
            return result;
        }
    },

    methods: {

        downloadPDF(datainame,
                    kundenlogo,
                    firma,
                    vorname,
                    nachname,
                    email,
                    datum,
                    gutschein
        ) {
            // Document of 297mm wide and 210mm high A4
            var doc = new jsPDF("p", "mm", "a4");

            doc.setProperties({
                title: 'Hausbaufuehrer.at',
                subject: 'Gutschein',
                author: 'Dieter Deutsch Dipl.FW',
                keywords: 'Gutschein Hausbauführer',
                creator: 'Dieter Deutsch'
            });

            doc.setFontSize(10);
            doc.setTextColor(91,91,91);

            /*
            var img = new Image()
            img.src = 'https://hausbaufuehrer.at/storage/systembilder/hausbaufuehrer_logo_n.gif'
            doc.addImage(img, 'gif', 2, 5, 30, 8)
            */

            doc.setFontSize(13);
            doc.setTextColor(13, 66, 149);
            doc.setFontType("bold")
            doc.text("hausbaufuehrer.at", 2, 10);
            doc.setFontType("normal")


            doc.setFontSize(14);
            doc.setTextColor(13, 66, 149);
            doc.setFontType("bold")
            doc.text("Gutschein", 184, 11);

            doc.setDrawColor(13, 66, 149);
            doc.setLineWidth(0.1);
            doc.line(2, 15, 208, 15, 'S')

            doc.setFontSize(10);
            doc.setFontType("normal")


            doc.setFontSize(11);
            doc.setTextColor(0, 0, 0);
            doc.setFontType("bold")
            doc.text("Gutschein von:", 2, 25);
            doc.setFontType("normal")


            var img2 = new Image()
            img2.src = kundenlogo
            doc.addImage(img2, 'JPEG', 2, 30, 30, 30)





            doc.setFontType("bold")
            doc.setTextColor(13, 66, 149);
            doc.text(firma.name, 38, 35);
            doc.setFontType("normal")
            doc.setTextColor(0, 0, 0);
            //doc.text(firma.strasse, 38, 40);
            doc.text(firma.plz + ' / ' + firma.Ort, 38, 45);




            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.1);
            doc.line(105, 22, 105, 63, 'S')



            //hier kommt der GS Empfänger rein
            doc.setFontSize(11);
            doc.setTextColor(0, 0, 0);
            doc.setFontType("bold")
            doc.text("Ausgestellt auf:", 110, 25);
            doc.setFontType("normal")

            doc.setFontType("bold")
            doc.setTextColor(13, 66, 149);
            doc.text(vorname +' '+ nachname, 110, 35);
            doc.setFontType("normal")
            doc.setTextColor(0, 0, 0);
            doc.text(datum, 110, 40);




            //untere linie
            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.1);
            doc.line(2, 66, 208, 66, 'S')



            // Gutschein Beschreibung
            doc.setFontSize(11);
            doc.setTextColor(0, 0, 0);
            doc.setFontType("bold")
            doc.text("Gutschein für:", 2, 75);
            doc.setFontType("normal")



            doc.setFontSize(15);
            doc.setTextColor(13, 66, 149);
            doc.setFontType("bold");






            var GutscheinLeistungT1 = gutschein.GSLeistung.replaceAll('<br />','');
            var GutscheinLeistungT2 = GutscheinLeistungT1.replaceAll('<p>','');
            var GutscheinLeistungT3 = GutscheinLeistungT2.replaceAll('</p>','');

            var GutscheinLeistungT4 = GutscheinLeistungT3.replaceAll('&uuml;','ü');
            var GutscheinLeistungT5 = GutscheinLeistungT4.replaceAll('&Uuml;','Ü');

            var GutscheinLeistungT6 = GutscheinLeistungT5.replaceAll('&ouml;','ö');
            var GutscheinLeistungT7 = GutscheinLeistungT6.replaceAll('&Ouml;','Ö');

            var GutscheinLeistungT8 = GutscheinLeistungT7.replaceAll('&auml;','ä');
            var GutscheinLeistungT9 = GutscheinLeistungT8.replaceAll('&Auml;','Ä');

            var GutscheinLeistungT10 = GutscheinLeistungT9.replaceAll('&szlig;','ß');


            var GutscheinLeistung = GutscheinLeistungT10.replaceAll('&euro;','€');

            doc.text(GutscheinLeistung, 105, 90, 'center');

            /*
            var GutscheinTitel = gutschein.GSInfo;
            var lines = doc.setFontSize(15)
                .splitTextToSize(GutscheinTitel, 150);
            doc.text(5,    115 +           11 / 150, lines);
            doc.setFontType("normal")
            */
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            doc.setFontType("bold");
            var GutscheinInfo = gutschein.GSInfo;

            var lines = doc.splitTextToSize(GutscheinInfo, 150);
            //doc.text(gutschein.GSLeistung, 105, 90, 'center');
            doc.text(34, 115 + 11/150, lines);
            doc.setFontType("normal");
            /*
            var GutscheinTitel = gutschein.GSLeistung;
            var lines = doc.setFontSize(15)
                .splitTextToSize(GutscheinTitel, 200,'center');
            doc.text(5,    110 +           11 / 200, lines);
            doc.setFontType("normal")
            */

            doc.setFontSize(9);
            doc.setTextColor(0, 0, 0);
            doc.setFontType("normal");
            var allgemeineInfo = "Sofern oben nicht anders angegeben gilt: Keine Barablöse möglich. Der Rechtsweg ist ausgeschlossen. Pro Person ist nur ein Gutschein einlösbar. " +
                "Der Gutschein ist nicht mit bereits reduzierter Ware oder ausgepriesenen Aktionen, anderen Gutscheinen sowie der Hausbaufuehrer Vorteilscard kombinierbar. Pro Person und Pro Haushalt ist nur ein Gutschein möglich.";
            var lines = doc.splitTextToSize(allgemeineInfo, 150);
            //doc.text(allgemeineInfo, 105, 220, 'center');
            doc.text(34, 230 + 11/150, lines);



            // doc.autoPrint({variant: 'non-conform'}); // öffnet das Drucken Fenster */
            doc.save(datainame);

        },


        setAgb(status){
                this.agb = status;
        },

        hasData(){
            if(this.vorname.length > 0 && this.nachname.length > 0 && this.email.length > 0 ){
                this.abg = false;
                document.getElementById("agb").disabled = false;

            }
            else{
                this.agb = false;
                document.getElementById("agb").disabled = true;
            }
        },



        /*
        beispielDebounce: _.debounce(function(){
           axios.post('/admin/newsletter/default/getEmails',{sendeBranchen: this.sendeBranchenFunction(),
                sendeBezirke: this.sendeBezirkeFunction()})
                .then((response)=>{
                    $('#success').html(response.data)
                    this.sendeEmails = response.data;
                    //console.log('sendeEmail: ',this.sendeEmails);
                    this.sendeEmailsFunction();
                })
        }, 1000),
        */


        loadFirma(id){
           let curObj = this;
            axios.get("/gutschein/getfirma/" + id).then(({data}) => (
                curObj.firma = data.firma,
                    curObj.gutschein = data.gutschein[0],
                    console.log('firma ist ', curObj.firma),
                    console.log('gutschein ist ', curObj.gutschein)

        ));
        },

    },

    mounted() {
        if (typeof(this.gutscheinProps) == "undefined" && typeof(this.firmaProps) == "undefined") {
            this.loadFirma(parseInt(this.$route.params.id));
        }
        else{
            this.firma      = this.firmaProps,
            this.gutschein  =  this.gutscheinProps,
                console.log(this.gutscheinProps);
            console.log('bin in Gutschein Formular.')

        }

        //document.getElementById("agb").disabled = true;
    }
}
</script>

<style scoped>

</style>
