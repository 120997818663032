
<!--- PremiumKunden Helper von Dieter in parent vue einbauen parameter überbeben
<premium-kunden :banner="  premiumku  " ></premium-kunden>
-->

<template>
   <div style="background-color:#e9ecef !important" class="p-2 mt-2">
        <div class="row">

            <div v-for="werbebanner in werbebannerNational" class="col-md-2 col-6">
                <a :href="'' + werbebanner.url" target="_blank" :title = werbebanner.url ><img class="img-fluid p-1"
                                                                                                      :src="'/storage/partnergfx/' + werbebanner.bildname"
                                                                                                      v-on:click="werbebannerMethode(werbebanner.KID, 6, -1 ,'Klicks')"
                                                                                                      :alt=  werbebanner.url
                                                                                                      :title= werbebanner.url
                ></a>
            </div>

        </div>
   </div>
</template>

<script>


    export default {
        name: 'WerbeBannerNationalWidget',
        //props: ['banner'],

        data() {
            return {
                werbebannerNational: this.werbebannerNational,
            };
        },


        methods: {
            //steht auf startseite unten
            getWerbebannerNational(){
                axios.get('/banner/getWerbebannerNational', {})
                    .then(response => {
                        this.werbebannerNational = response.data
                        //console.log(this.werbebannerNational);
                    })
            },


            werbebannerMethode: function(KID,typID,flag,typ) {
                var ADID;
                var bannerKlicks;
                axios.get('/banner/getADID/' + KID + '/' + typID , {})
                    .then(response => {
                        this.ADID = response.data

                        axios.get('/statistik/firma/setBannerHitsKlicks/' + this.ADID + '/' + typID + '/' + flag + '/' + typ, {})
                            .then(response => {
                                this.bannerKlicks = response.data
                               // console.log(this.bannerKlicks);
                            });
                    });
            },


        },

        mounted() {
            this.getWerbebannerNational();
            //console.log(this.getWerbebannerNational());
            console.log('bin in WerbebannerNationalWidget von Dieter.')
        }
    }
</script>
