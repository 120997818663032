<template>
    <div v-if="zeigeBanner == true" class="mb-5">
        <p class="text-right text-muted border-bottom">Prämiumkunden National</p>
        <div class="position-relative px-3 pt-3 bg-blue" >
            <div class="row">
                <div :name="'isTelefonNr' + item.id" v-for="item in banner" class="col-md-4">
                    <div  class="card mb-3 mx-0" >
                        <div class="row no-gutters">
                            <div class="col-md-4">
                                <img :src="'/storage/logos/' + item.cover_image"  class="card-img p-2" alt="item.firmaname">
                            </div>
                            <div class="col-md-7">
                                <div class="mt-1 pt-1 ml-1 pl-1 card-body text-black-50">
                                    <h5 class="card-title" style="min-height:64px;min-height:4rem;">{{item.firmaname}}</h5>
                                    <div class="card-text">
                                        <address>
                                            {{item.plz}} {{item.Ort}} <br>
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters border-top" >
                            <div class="col-md-12 text-center p-3 pt-0">
                                <a class="mr-3" :href="'#'" v-if="isTelefonNr.id !== item.firmaID" @click="zeigeTelefonNr(item.firmaID),setHitKlicks('telclick',item.firmaID)"><i class="fas fa-phone-alt"></i> Anzeigen</a>
                                <a class="mr-3" :href="'#'" v-if="isTelefonNr.id === item.firmaID"><i class="fas fa-phone-alt"></i>&nbsp;{{item.tel}}</a>
                                <a :href="item.slug" @click="setHitKlicks('KlicksPrem',item.firmaID)"><i class="fas fa-info-circle"></i> Mehr Info</a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: {
            mysuchmuster: {
                type: String
            },

        },

        data() {
            return {
                banner: [],
                suchmuster: this.mysuchmuster,
                isTelefonNr: {
                    id: false,
                },
                zeigeBanner: false,
                BRID: 0,


            }
        },

        methods: {
            setHitKlicks(type,slug){
                axios.get('/statistik/firma/setKlicks/'+ type + '/' + slug).then('OK');
            },

            getBanner(suchwort) {
                var BRID = 0;
                axios.get('/banner/getBrancheID/' + suchwort, {})
                    .then(response => {
                        this.BRID = response.data

                        if(this.BRID !== ''){
                        axios.get('/banner/getPremiumBannerNational/' + this.BRID, {})
                            .then(response => {
                                this.banner = response.data
                                //console.log(this.werbeFirmen);
                                if (this.banner.length > 0) {
                                    this.zeigeBanner = true;
                                }
                            })
                    }else{
                            this.banner = [];
                            this.zeigeBanner = false;
                        }
                    })



            },

            zeigeTelefonNr(id){
                this.isTelefonNr.id =  id;
            },

            entferneSlash(mysuchmuster){
                  if(mysuchmuster.search("/") !== -1) {
                     mysuchmuster =  mysuchmuster.replace("/", " ")
                      return mysuchmuster;
                  }
                return mysuchmuster;
            }
        },
        mounted() {
            this.getBanner(this.entferneSlash(this.mysuchmuster));
            //this.getBanner(this.mysuchmuster);
            Fire.$on('searching',() => {
                this.suchmuster = this.entferneSlash(this.mysuchmuster);
                this.getBanner(this.entferneSlash(this.mysuchmuster));
            })
            //var branchen = [14,40,82,199,233];

            console.log('bin in PremiumBannerNational.')
        }
    }

</script>
