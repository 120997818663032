/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

//import HausanbieterBannerWidget from "./components/banner/HausanbieterBannerWidget";

require('./bootstrapstartseite');

window.Vue = require('vue');
import moment from 'moment';

//router
import routes from './router/routespublic';

import VueRouter from "vue-router";
Vue.use(VueRouter)




import { Form, HasError, AlertError } from 'vform';

import swal from 'sweetalert2'
window.swal = swal;
const toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

window.toast = toast;
window.Form = Form;

Vue.filter('upText', function(text){
    return text.charAt(0).toUpperCase() + text.slice(1)
});

Vue.filter('myDate',function(created){
    return moment(created).format('MMMM Do YYYY');
});

window.Fire =  new Vue();
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

//Vue.component('my-locations2-alle-branchen', require('./components/helper/MyLocations2AlleBranchen').default);
//Vue.component('my-locations2-alle-branchen-bl', require('./components/helper/MyLocations2AlleBranchenBl').default);
//Vue.component('my-locations2-alle-branchen-bz', require('./components/helper/MyLocations2AlleBranchenBz').default);

//Vue.component('hausanbieter-banner-widget', require('./components/banner/HausanbieterBannerWidget').default);

Vue.component('pagination', require('laravel-vue-pagination'));
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
    speed: '4s',
    color: '#38bf71',
    failedColor: 'red',
    height: '10px',

    autoFinish: true,
    thickness: '5px',
})

const router = new VueRouter({
    mode: 'history',
    routes, // short for `routes: routes`
   /* geht in Safari
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
    */
})


import Startseite from './components/startseite/Startseite';
//import FirmaDetailSeite from './components/firma/FirmaDetailseite';
//import LoadVisitenkarte from './components/firma/LoadVisitenkarte';
import FullBannerWidget from './components/banner/FullBannerWidget.vue';
import BannerWidget from './components/banner/BannerWidget.vue';
//import BundeslandHausanbieterBranchen from "./components/BundeslandHausanbieterBranchen";
//import BundeslandHausanbieterBranchenBezirk from "./components/BundeslandHausanbieterBranchenBezirk";
//import BundeslandSelectfeld from "./components/helper/MyLocations1";
//import MyLocations2 from "./components/helper/MyLocations2";
import BauangeboteBanner from "./components/banner/WerbebannerNationalWidget";

//import ExampleComponent from './components/firma/ExampleComponent';
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#appfrontend',
    router,
    components:{
        Startseite,
        FullBannerWidget,
        BannerWidget,
       // FirmaDetailSeite,
       // LoadVisitenkarte,
       // BundeslandHausanbieterBranchen,
       // BundeslandHausanbieterBranchenBezirk,BundeslandSelectfeld,MyLocations2,
        BauangeboteBanner

    },
    data:{
        seoStartseiteAnzeigen: true,
    },
    methods:{

    },
    mounted() {
        //erst wenn alle abhänigkeiten geladen wurden ausführen
        this.$nextTick(function () {
            // wird erst ausgeführt wenn alle children geladen wurden
            // Code that will run only after the
            // entire view has been rendered


            //console.log('daten von starteite ref',this.$refs.startseite.$children[0].$refs.sf1.$data.sf1) // geht
            //console.log('daten von starteite ref',this.$refs.startseite.$children[0].$children[0].$children[0].$data.searchText = 'steht in eigabefeld geht')
            //  console.log('bin in root ',this.$refs.startseite.$refs.mainformWidget.$refs.sf1.$data.items);

        });

        Fire.$on('show-startseite-content',(value) => {
            this.seoStartseiteAnzeigen = value;
        })


    }
});
