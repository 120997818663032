<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card card-default">
                    <div class="card-header">Kein Treffer</div>

                    <div class="card-body">
                        Kein Treffer
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import _ from "lodash";
    //import myLocationsCheckbox from "../../helper/MyLocationsCheckbox";

    var moment = require('moment');


    export default {
        components:{
            //myLocationsCheckbox
        },
        props: {
           // title: String,
           // likes: Number,
           // isPublished: Boolean,
           // commentIds: Array,
           // author: Object,
           // callback: Function,
           // contactsPromise: Promise // or any other constructor
        },
        name: "keinTreffer",
        data () {
            return {
                moment: moment,
                csrf: document.head.querySelector('meta[name="csrf-token"]').content,

            }
        },

        computed: {

        },


        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },

            tolowercase: function (value) {
                if (!value) return ''
                value = value.toLowerCase();
                return value
            },


            arraytostring: function (value) {
                var str = value;
                var res = str.split("#");
                var result = res.join(' | ');
                // console.log(result);
                return result;
            }
        },

        methods: {

            /*
            beispielDebounce: _.debounce(function(){
               axios.post('/admin/newsletter/default/getEmails',{sendeBranchen: this.sendeBranchenFunction(),
                    sendeBezirke: this.sendeBezirkeFunction()})
                    .then((response)=>{
                        $('#success').html(response.data)
                        this.sendeEmails = response.data;
                        //console.log('sendeEmail: ',this.sendeEmails);
                        this.sendeEmailsFunction();
                    })
            }, 1000),
            */


        },

        mounted() {
            console.log('bin in KeinTreffer.')
        }
    }
</script>

<style scoped>

</style>
