<template>
    <div>
        <mainform-widget ref="mainformWidget"></mainform-widget>



        <img v-if="startbildAnzeigen" class="d-none  mt-2  img-fluid"  src="/storage/systembilder/startbild.jpg" />

      <!-- Bauangebote Modul Start deaktiviert 19 April -->

      <h1 class="d-none text-right  border-bottom mt-4">Bauangebote einholen und Zeitsparen.</h1>
      <div class="d-none row bg-blue mx-0">
        <div class="col-md-4 text-center v-align-middle">
          <img src="/storage/images/angebote-bg.png" id="angimg" alt="Angebote einholen" class="pt-4">
          <h1>Angebote einholen<br />
            <small>So einfach geht's</small>
          </h1>

        </div>
        <div class="col-md-4 pt-3 my-2 border-right">
          <p class="">Nutzen Sie diese Möglichkeit, um in drei Schritten einfach und bequem mehreren Anbietern automatisch eine Angebotsanfrage zu schicken. Wählen Sie im ersten Schritt das gewünschte Bundesland und Bezirk aus. </p>

        </div>
        <div class="col-md-4 pt-3 ">
          <my-locations2-startseite></my-locations2-startseite>


        </div>
      </div>
      <!-- Bauangebote Modul End -->

       <!-- <img v-if="startbildAnzeigen" class="img-fluid mt-2" src="/storage/systembilder/gewinnspiel_blau2.jpg" title="Gewinnspiel" alt="Gewinnspiel" /> -->
        <div class="d-none container bg-blue text-white pt-4 pl-4 pr-4 pb-2 mb-5">
            <div class="row">
                <div class="col-md-8">
                    <h1 class="my-0">Gewinnspiel auf Hausbaufuehrer.at</h1>
                    <p>Als Dankeschön für die Treue unserer Fans verlosen wir insgesamt 3 Original Makita Akku-Elektronik Bohrschrauber!</p>
                </div>
                <div class="col-md-4 text-right">
                    <a class="btn btn-secondary btn-lg mt-2 p-3" href="/aktion/gewinnspiel" role="button">Zum Gewinnspiel</a>
                </div>
            </div>
        </div>


        <!--
                <img v-if="startbildAnzeigen" class="mt-2" sizes="(max-width:321px) 72.3vw,(max-width:376px) 129.5vw,(max-width:415px) 74.5vw,
                   (max-width:769px) 104.3vw, (max-width:813px) 98.8vw, (max-width:835px) 96vw, (max-width:1025px) 106vw, (max-width:1113px) 97.5vw,
                   ,(max-width:1281px) 90vw,(max-width:1367px) 94.8vw, (max-width:1441px) 90vw,
                      calc(50vw - 100px)"
                     srcset="/storage/systembilder/startbild-320.jpg 321w,
                             /storage/systembilder/startseite-420.jpg 376w,
                             /storage/systembilder/startseite-420.jpg 415w,
                             /storage/systembilder/startseite-960.jpg 769w,
                             /storage/systembilder/startseite-960.jpg 813w,
                             /storage/systembilder/startseite-960.jpg 835w,
                             /storage/systembilder/startseite-960.jpg 1025w,
                             /storage/systembilder/startseite-1024.jpg 1113w,
                             /storage/systembilder/startseite-1024.jpg 1281w,
                             /storage/systembilder/startseite-960.jpg 1367w,
                             /storage/systembilder/startseite-1024.jpg 1441w"

                     src="/storage/systembilder/startseite-1024.jpg"
                     alt="Startseite Bild Hausbaufuehrer.at">
        -->
        <!-- Head -->


        <!-- Default Site Start -->
        <div v-if="sf1 == 0 && sf2 == 0" >

            <div class="row">
                <div class="col-md-12 "><p class="text-right  border-bottom d-md-none mt-3">National Banner</p></div>
            </div>
            <werbe-banner-national-widget></werbe-banner-national-widget>


            <!-- Bauangebote einholen platzhalter -->
          <!-- Bauangebote Modul Start wieder eingebaut 19 April -->

          <h1 class="text-right  border-bottom mt-4">Bauangebote einholen und Zeitsparen.</h1>
          <div class="row bg-blue mx-0">
            <div class="col-md-4 text-center v-align-middle">
              <img src="/storage/images/angebote-bg.png" id="angimg" alt="Angebote einholen" class="pt-4">
              <h1>Angebote einholen<br />
                <small>So einfach geht's</small>
              </h1>

            </div>
            <div class="col-md-4 pt-3 my-2 border-right">
              <p class="">Nutzen Sie diese Möglichkeit, um in drei Schritten einfach und bequem mehreren Anbietern automatisch eine Angebotsanfrage zu schicken. Wählen Sie im ersten Schritt das gewünschte Bundesland und Bezirk aus. </p>

            </div>
            <div class="col-md-4 pt-3 ">
              <my-locations2-startseite></my-locations2-startseite>


            </div>
          </div>
          <!-- Bauangebote Modul End -->


            <div class="row mt-4">
                <div class="col-md-12">
                    <h1 class="text-right  border-bottom">Unternehmen im Focus</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <im-focus-banner></im-focus-banner>
                </div>
            </div>





        </div>

        <!-- Default Site End -->

        <!-- Branchen Hits Start -->
        <div id="containerSf1" v-else-if="sf1 == 1 && sf2 == 0 && absenden == 'ja'">
            <vue-progress-bar></vue-progress-bar>
            <ResultsSf1  :absenden-props="absenden" v-bind:suchmuster="suchmuster"></ResultsSf1>
        </div>

        <!-- Branchen Hits End -->

        <!-- Firmen Hits Start -->
        <p  v-else-if="sf1 == 0 && sf2 == 1" >
            <vue-progress-bar></vue-progress-bar>
            <ResultsSf2 v-bind:suchmuster="suchmuster"></ResultsSf2>
        </p>
        <!-- Firmen Hits End -->

        <!-- Branchen und Firmen Start -->
        <div  v-else="sf1 == 1 && sf2 == 1" >

            <div class="p-3 mb-5 bg-warning text-white text-center">
                <h1 class="d-inline"><a :href="'/'"><i class="fas fa-exclamation-triangle fa-2x mr-2"></i>NUR EIN SUCHFELD BEFÜLLEN, NEUE SUCHE <span style="text-decoration: underline; font-weight: bolder">hier KLICKEN</span> <i class="fas fa-angle-double-right fa-2x"></i></a></h1></div>

            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <p class="text-right text-muted border-bottom">Unternehmen im Focus</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <im-focus-banner></im-focus-banner>
                    </div>
                </div>
            </div>


            <werbe-banner-national-widget></werbe-banner-national-widget>

            <div class="mt-0">
                <h1 class="text-right border-bottom">Mit wenigen Klicks zu den besten Baufirmen Österreichs</h1>
                <p>Das useroptimierte Bauportal hausbaufuehrer.at bietet Ihnen nicht nur eine Übersicht über alle empfehlenswerten Baufirmen in Österreich, sondern auch die besten Partner für Sanierung, Renovierung, Innenausbau, Garten und viele mehr auf einen Klick. Mit der Schnell- oder Bezirkssuche finden Sie Baufirmen direkt in Ihrer Nähe, mit der Branchen-Produktsuche wiederum überregionale Spezialanbieter. Informieren Sie sich auf unseren hilfreichen Themenseiten zum Thema Hausbau und Sanierung sowie zu allen Aspekten des Hausbaus – von A wie Alarmanlage bis Z wie Zaun.
                    Legen Sie Wert auf saubere Arbeit, exakte Planung, effiziente Durchführung, transparente Kosten und ein ausgezeichnetes Service. Nehmen Sie Hersteller, Händler, Dienstleister und Handwerker genau unter die Lupe, bevor Sie sich entscheiden und wählen Sie den Spezialisten, der perfekt zu Ihren Ansprüchen passt. Gleich, ob landesweites Unternehmen oder regionaler Betrieb – mit dem Online-Firmenportal von hausbaufuehrer.at finden Sie schnell und sicher die richtige Baufirma für Hausbau und Renovierung. Was zählt, ist Ihre Zufriedenheit.
                </p>
                <h6 class="">Angebote einholen &amp; bis zu 15 % beim Hausbau sparen</h6>
                <p>Auf der Bauplattform www.hausbaufuehrer.at sind die verschiedensten Firmen aus der Baubranche vertreten: vom Hausbau, Umbau, Renovierung bis zur Altbausanierung. Bei uns haben Sie als Bauherr die Möglichkeit, von der Planung bis zur Fertigstellung Ihres Vorhabens, nach den verschiedensten Firmen zu suchen. Mit unserem Angebotstool können Sie in nur 3 Schritten schnell und bequem gleich von mehreren Anbietern in Österreich unverbindliche Angebote einholen. Vergleichen Sie ohne großen Aufwand die Kosten unterschiedlicher Baufirmen und wählen Sie für sich das passende Angebot aus. Wenn Sie zusätzlich bis zu 15% sparen möchten, können Sie ganz einfach die kostenlose Vorteilskarte von hausbaufuehrer.at anfordern. Mit dieser Vorteilskarte erhalten Sie bei allen unseren Vorteilskarten-Partnerfirmen Rabatte.
                    Die Vorteilskarte-Partnerfirmen sind bei allen Suchergebnissen jeweils extra in blau gekennzeichnet.
                </p>
            </div>

        </div>
        <!-- Branchen und Firmen End -->

        <!-- Default Site End -->
        <!-- Head Ende -->
    </div>
</template>
<script>

//import mainformWidget from '/Users/dieterdeutsch/Web/Projects/hausbaufuehrer1/resources/js/components/mainsuche/mainformWidget.vue';
import mainformWidget from '../../../../resources/js/components/suchfilter/mainformWidget.vue';
import ResultsSf1 from '../../../../resources/js/components/suchfilter/ResultsSf1.vue';
import ResultsSf2 from '../../../../resources/js/components/suchfilter/ResultsSf2.vue';
import WerbeBannerNationalWidget from '../../components/banner/WerbebannerNationalWidget.vue';
import ImFocusBanner from '../../components/banner/ImFocusBanner';
import MyLocations2 from "../helper/MyLocations2";
import MyLocations2Startseite from "../helper/MyLocations2Startseite";



export default {
    components:{ResultsSf1,ResultsSf2,mainformWidget,WerbeBannerNationalWidget,ImFocusBanner,MyLocations2,MyLocations2Startseite},
    name: "startseite",
    data () {
        return {
            absenden: 'nein',
            sf1: 0,
            sf2: 0,
            suchmuster: '',
            startbildAnzeigen: true,
        }
    },



    methods: {

    },



    created() {
        Fire.$on('setSf1',(data) => {
            this.sf1 = data;
            this.startbildAnzeigen = false;
        })
        Fire.$on('setSf2',(data) => {
            this.sf2 = data;
            this.startbildAnzeigen = false;
        })
        Fire.$on('setSuchmuster',(data) => {
            this.suchmuster = data;
        })


        Fire.$on('setSuchmuster1Wertevent',(data) => {
            this.suchmuster = data;
        })

        Fire.$on('setSuchmuster2Wertevent',(data) => {
            this.suchmuster = data;
        })

        Fire.$on('absendenevent',(data) => {
            this.absenden = data;
        });
    },



    mounted() {


        //this.sf1 =  this.$refs.mainformWidget.$refs.sf1.$data.sf1;

        // console.log('bin in startseite ',this.$refs.mainformWidget.$refs.sf1.$data.sf1);
        //this.sf1 = this.$options.name.Sf1.sf1;
    }
}

</script>

<style>
.bg-warning.text-white h4.d-inline a {
    text-decoration: underline;
    color: #0a3272;
}
.bg-warning.text-white h4.d-inline a:hover{
    text-decoration: none;
    color:white;
}



</style>

