
<!--- PremiumKunden Helper von Dieter in parent vue einbauen parameter überbeben
<premium-kunden :banner="  premiumku  " ></premium-kunden>
-->

<template>
  <div>
     <div id="carousel" class="mt-4 carousel slide carousel-fade" data-ride="carousel">
            <div class="carousel-inner">
                <div v-for="firma in werbeFirmen" :class="'carousel-item ' +  firma.active" >
                    <a :href="'' + firma.url" target="_blank" :title=  firma.url><img class="d-block w-100"
                                                                                             :src="'/storage/partnergfx/'+ firma.bildname"
                                                                                             v-on:click="blinkbannerNational(firma.KID, 2, -1 ,'Klicks')"
                                                                                             alt= firma.url
                                                                                             :title=  firma.url
                    >
                    </a>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
    export default {
        //name: 'banner-widget',
        //props: ['banner'],
        data() {
            return {
                werbeFirmen: [],
            };
        },

        methods: {
            getBannerRechts() {
                axios.get('/banner/getTurmBanner', {})
                    .then(response => {
                        this.werbeFirmen = response.data
                        //console.log(this.werbeFirmen);
                        this.setBlinkbannerImpressionen(response.data);
                    })
            },



            blinkbannerNational: function(KID,typID,flag,typ) {
                //statistik
                //axios.get('/vue/firma/statistik/visitenkartenklicks/' + id).then('OK');
                //var ADID = axios.get('/statistik/firma/setHitsKlicks/' + id + '/Klicks').then('OK');
                //var ADID = axios.get('/vue/firma/statistik/wandle/KID/in/ADID/' + id ).then('OK')

                var ADID;
                var bannerKlicks;
                axios.get('/banner/getADID/' + KID + '/' + typID , {})
                    .then(response => {
                        this.ADID = response.data

                        axios.get('/statistik/firma/setBannerHitsKlicks/' + this.ADID + '/' + typID + '/' + flag + '/' + typ, {})
                            .then(response => {
                                this.bannerKlicks = response.data
                                //console.log(this.bannerKlicks);
                            });
                    });
            },


            setBlinkbannerImpressionen(daten){
                //console.log('blinkbanner startseite wurde eingeblendet',daten);
                daten.forEach(function(item){
                    ////banner/fullbanner/setImpressum/{id}/{blid}/{typ}
                    axios.get('/banner/blinkbanner/setImpressionen/' + item.bannerlistID + '/-1/Views' , {})
                        .then(response => {
                            //this.werbeFirmen = response.data
                           // console.log(response.data);
                        })

                });
            },

        },

        mounted() {
            this.getBannerRechts();
            console.log('bin in BannerWidget von Dieter.')
        }
    }
</script>
