<template>
    <div>
        <div class="my-4 clearfix">
            <div class="row">
                <div class="col">
                    <h5 class="">Treffer mit letzter Auswahl: <strong>{{suchmuster}}</strong></h5>
                </div>
                <div class="col-md-auto text-right">
                    <a href="/"><i class="fas fa-search"></i> Neue Suche</a>
                </div>
            </div>

        </div>
        <div class="firmen results  mt-5" >


            <div :id="'firmenID-' + item.id" v-for="(item,index) in items" :key="item.id" v-if="index !== 'premiumkunden'" class="media border-bottom my-2 py-2">

                <div style="cursor: pointer" :href="'/'+ item.slug" :title="'Firma ' + item.name + ' - Branche: ' + item.branchen" @click="setHitKlicks2('Klicks',item.id,item.slug)"><img :src="'/storage/logos/' + item.cover_image" class="align-self-center mr-3  " :alt=" item.name "></div>



                <div class="media-body">
                    <h5 class="">{{ item.name | ucFirst | kurz}}</h5>
                    <p class="bezirkList mb-0">{{ item.Bezirk }}</p>

                    <span class="mehrInfo">
                       <div style="cursor: pointer" class="mybtn btn-default" :href="'/'+ item.slug" :title="item.branchen + ' - ' + item.id" @click="setHitKlicks2('Klicks',item.id,item.slug)" role="button"><i class="fas fa-info-circle fa-lg" ></i>  mehr info...</div>
                </span>
                    <!-- <a class="mybtn btn-default" :href="'/'+ item.slug" :title="item.branchen" @click="setHitKlicks('Klicks',item.id)" role="button"><i class="fas fa-info-circle fa-lg" ></i>  mehr information...</a> -->
                </div>
            </div>
            <div v-if=" anzahl == 0" class="text-center">Keine Daten gefunden</div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        suchmuster:{
            type: String
        },
    },

    data () {
        return {
            success: false,
            error: false,
            output: '',
            anzahl: 0,
            items : [],
            //mysuchmuster: this.suchmuster
        }
    },

    methods: {
        loadFirmen(suchmuster){
            this.$Progress.start();
            let query = suchmuster;
            axios.get('/firma/suchfilter/vue/getIds/0/1/' + query)
                .then((data) => {

                    this.items = data.data,
                        this.anzahl = data.data.length
                    this.$Progress.finish();

                })
                .catch(() => {
                })
        },

        setHitKlicks(type,slug){
            axios.get('/statistik/firma/setKlicks/'+ type + '/' + slug).then('OK');
        },

        // nur das geht am Server in Wien
        setHitKlicks2(type,id,slug){
            let currentObj = this;
            // window.alert('type ' + type + ' und id ' + id + ' und slug ' + slug);
            // console.log('type ' + type + ' und id ' + id + ' und slug ' + slug);
            var root = location.protocol + '//' + location.host + '/';
            // axios.get('/statistik/firma/setKlicks/'+ type + '/' + slug).then('OK');
            axios.get('/statistik/firma/setKlickszwei/'+ type + '/' + id + '/' + slug).then(({response}) => (

                console.log(response),
                    currentObj.success = response.succes
            )).catch(function (error) {
                currentObj.error = error
            });
            window.location.replace(root + slug.toLowerCase())
        },

    },

    filters: {
        kurz: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.substr(0, 80);
        },

        ucFirst(value) {
            if (!value) return ''
            value = value.toString()
            return value.substring(0, 1).toUpperCase() + value.substring(1);
        }
    },

    created() {


        //this.suchmuster = this.$root.$children[0].$refs['mainformWidget'].$refs.sf1.suchmuster;
        /*
         Fire.$on('searching',() => {
             //this.suchmuster = this.$root.$children[0].$children[0].$children[1].suchmuster2;
             //this.suchmuster = 'katzbeck';
             this.loadFirmen(this.suchmuster);
         })
         */

    },

    mounted() {

        this.loadFirmen(this.suchmuster);

    }
}
</script>
