<template>
    <div class="form-group-lg col-sm-12 col-md-5 mb-0 pb-0 autocomplete">

        <input ref="suchmuster1" id="suchmuster1"  type="text" v-model="suchmuster1"
               @click="clickFeld1()"
               @keyup="inputChanged"
               @keydown.down="onArrow"
               @keydown.up="onArrow"
               @keydown.tab="reset()"
               @keydown.enter="nextPlease()"
               class="form-control form-control-lg"
               placeholder="Branche, Bezirk"
        />

        <i class="deleteInhalt pl-0 fas fa-times-circle text-muted" @click="reset()"></i>
        <span id="suchhilfe" style="font-size: 0.6rem;" class="">neue Suche X klicken</span>
        <div v-if="isOpen" class="w-auto automplete-wrapper m-0">
            <div v-for="(user, i) in filteredBranchenList" :key="i" class="autocomplete-results list-group-item list-group-item-action py-1" v-show="isOpen" :class="{ 'is-active': i === arrowCounter }">
                <div style="cursor: pointer"   @click="setResult(user.name),setCursor(),setMainWidgetSuchmuster()">{{ user.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Sf1",
    data() {
        return {

            branchenList: [],
            suchmuster1: '',
            suchmuster1Alt: '',
            arrowCounter: -1,
            isOpen: false,
            filteredBranchenList: []
        }
    },

    methods: {


        nextPlease: function () {
            console.log('nextplase gedrpckt');
            if(this.suchmuster1.length !== this.suchmuster1Alt.length) {
                Fire.$emit('absendenevent', 'ja');
                Fire.$emit('setSf1', 1);
                Fire.$emit('setSf2', 0);
                Fire.$emit('setSuchmuster1Wertevent', this.suchmuster1);
                document.getElementById('startformsendenbutton').focus();
            }
        },


        setCursor(){
            this.$nextTick(() => this.$refs.suchmuster1.focus())
            this.suchmuster1 += ' ';
        },
        clickFeld1(){
            Fire.$emit('absendenevent','nein');
            Fire.$emit('setSf1', 0);
            Fire.$emit('setSf2', 0);

            Fire.$emit('is-openSf2', false);
            Fire.$emit('setSuchmuster2Wertevent', '');
            Fire.$emit('setFilteredFirmenList', []);
        },

        setMainWidgetSuchmuster(){


            if(this.suchmuster1.length > 0) {
                Fire.$emit('setSuchmuster1Wertevent', this.suchmuster1);

                this.filteredBranchenList = [];
                this.isOpen = false;

                Fire.$emit('setSf1', 0);
            }
            else {
                this.isOpen = false;
            }

        },



        pressEnter(){
            if(this.suchmuster1.length > 0){
                // this.setSuchmuster(); // optimiert den Link
                Fire.$emit('setSf1', 1)
                Fire.$emit('setSf2', 0)


            }
        },

        setSuchmuster: function () {
            Fire.$emit('absendenevent','nein');
            let neuesSuchmuster = '';
            neuesSuchmuster = this.suchmuster1.replace("/","-");
            neuesSuchmuster = this.suchmuster1.replace(", "," ");
            Fire.$emit('setSuchmuster', neuesSuchmuster);
        },


        // autocomplete
        loadAlleBranchen(){
            axios.get('/admin/seo/branche/get/allebranchen')
                .then((response) => {
                    this.branchenList = response.data
                })
                .catch(() => {
                })
        },


        setResult(text) {
            Fire.$emit('absendenevent','nein');
            Fire.$emit('setSf1', 0);
            this.suchmuster1 = text
            Fire.$emit('setSuchmuster1Wertevent', this.suchmuster1);
            this.suchmuster1Alt = this.suchmuster1;
        },

        onArrow(event) {
            if (this.filteredBranchenList.length > 0) {
                this.arrowCounter = event.code == "ArrowDown" ? ++this.arrowCounter : --this.arrowCounter;
                if (this.arrowCounter >= this.filteredBranchenList.length)
                    this.arrowCounter = (this.arrowCounter) % this.filteredBranchenList.length;
                else if (this.arrowCounter < 0)
                    this.arrowCounter = this.filteredBranchenList.length + this.arrowCounter;
                this.setResult(this.filteredBranchenList[this.arrowCounter].name += ' ');
            }

        },
        inputChanged(event) {
            if (event.code == "ArrowUp" || event.code == "ArrowDown")
                return;

            this.filteredBranchenList = [];

            if (event.code == "Enter") {
                this.suchmuster1Alt = this.suchmuster1 + ' a';
                this.isOpen = false;
                return;
            }

            var filtered = this.branchenList.filter((user) => {
                return user.name.match(this.suchmuster1.toLowerCase())
            });


            this.filteredBranchenList.push(...filtered)

            if(this.filteredBranchenList.length > 0) {
                this.isOpen = true
            }
            else{
                this.isOpen = false
            }
            Fire.$emit('setSuchmuster1Wertevent', this.suchmuster1);


            //console.log(this.filteredBranchenList)
        },

        reset() {
            this.suchmuster1 = '';
            this.suchmuster1Alt = '';
            Fire.$emit('setSf1', 0);
            Fire.$emit('setSuchmuster1Wertevent', '');
            this.isOpen = false;
            this.filteredBranchenList = [];
            this.$nextTick(() => this.$refs.suchmuster1.focus())

        },

    },

    mounted() {

        this.loadAlleBranchen();
        this.$nextTick(() => this.$refs.suchmuster1.focus());

        Fire.$on('setSuchmuster1Wertevent',(value) => {
            this.suchmuster1 = value;

        });


        Fire.$on('is-openSf1',(value) => {
            this.isOpen = value;
        });
        Fire.$on('setFilteredBranchenList',(value) => {
            this.filteredBranchenList = value;
        });




    }

}



</script>

<style scoped>

.deleteInhalt {
    position: relative;
    top: -30px;
    left: -10px;
    float: right;
    cursor: pointer !important;
}

.automplete-wrapper{
    position:relative;
    top: -1.5rem !important;
    overflow:hidden;
    max-height: 22.5rem !important;
}

/*
.autocomplete {
    position: relative;

}

.autocomplete-results {
    padding: 0;
    margin: 0;

    border: 1px solid #eeeeee;

    overflow: auto;
    width: 100%;
}

.autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
    background: red;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;

}
*/
.is-active {
    background-color: #dedede;
}

</style>
