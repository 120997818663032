<template>
    <form v-if="showSuchmaske" style="background-color:#0d4295; color:white; " class="border p-3 pt-4 pb-0" >
        <div class="form-row mb-0 pb-0">
            <h3 class="text-center mb-0 mt-0 mb-3 w-100">
                <i class="fas fa-search fa-lg white"></i> Finde die besten Baufirmen auf hausbaufuehrer.at
            </h3>
            <sf1 class="w-100" ></sf1> <sf2 class="w-100"></sf2>
            <div class="float-left"><button type="button" id="startformsendenbutton" @click="sendeDaten()"   class="btn btn-secondary py-2">Suchen <i class="fas fa-search"></i></button></div>
        </div>
    </form>
</template>

<script>


import sf1 from './Sf1.vue';
//import location from './Location.vue';
import sf2 from "./Sf2.vue";
import _ from "lodash";

export default {
    //components:{waswosuche},
    components:{sf1,sf2},
    name: "mainformWidget",
    data () {
        return {
            showSuchmaske: true,
            suchmuster1: '',
            suchmuster2: '',

        }
    },

    methods:{
        sendeDaten(){

            Fire.$emit('absendenevent','ja');

            //Fire.$emit('geklicktevent', true);

            this.setSuchmuster();

            //Fire.$emit('geklicktevent', true);
            if(this.suchmuster1.length > 0){
                //this.setSuchmuster(); // optimiert den Link
                Fire.$emit('setSf1', 1)
                Fire.$emit('setSf2', 0)

                return;
            }

            if(this.suchmuster2.length > 0){
                //this.setSuchmuster(); // optimiert den Link
                Fire.$emit('setSuchmuster2Wertevent', this.suchmuster2.trim());
                Fire.$emit('setSf1', 0)
                Fire.$emit('setSf2', 1)
                return;
            }





        },


        setSuchmuster: function () {

            let neuesSuchmuster = '';
            neuesSuchmuster = this.suchmuster1.replace("/","-");
            neuesSuchmuster = this.suchmuster1.replace(", "," ");
            neuesSuchmuster = this.suchmuster1.replace(","," ");
            neuesSuchmuster = this.suchmuster1.replace(","," ");

            Fire.$emit('setSuchmuster', neuesSuchmuster);
        },

        setSuchmuster_alt: function () {
            let neuesSuchmuster = '';
            neuesSuchmuster = this.suchmuster1.replace("/","-");
            neuesSuchmuster = this.suchmuster1.replace(",","");

        },

    },

    mounted() {
        Fire.$emit('absendenevent','nein');

        Fire.$on('setSuchmuster1Wertevent',(value) => {
            this.suchmuster1 = value;
        });

        Fire.$on('setSuchmuster2Wertevent',(value) => {
            this.suchmuster2 = value;
        });

        Fire.$on('showsuchmaskeevent',(value) => {
            this.showSuchmaske = value;
        });

    }
}
</script>

<style scoped>

</style>
