
<!--- PremiumKunden Helper von Dieter in parent vue einbauen parameter überbeben
<premium-kunden :banner="  premiumku  " ></premium-kunden>
-->

<template>
<div>
    <div id="kundenImFocusDEAKTIVIERT" class="d-none carousel slide" data-ride="carousel">
        <div class="carousel-inner">
            <div v-for="firma in werbeFirmen" :data-interval="8000" :class="'carousel-item ' +  firma.active" >
                <div class="jumbotron m-0 p-0">

                        <div class="row">
                            <div class="col-md-6">
                                        <a :href="'http://' + firma.Link" target="_blank" :title=  firma.Headline><img class="d-block w-100 mb-3"
                                                                                                                :src="'/storage/images/content/'+ firma.Image"
                                                                                                                v-on:click="sethitimfocusbanner(firma.KID, 5, 0 ,'Klicks')"
                                                                                                                :alt= firma.Headline
                                                                                                                :title=  firma.Headline
                                        >
                                        </a>
                            </div>
                            <div class="col-md-6">
                                <div id="pr-wrapper" class="v-align-middle  ">
                                        <div class="text-center">
                                            <i class="fas fa-medal fa-6x"></i>
                                            <h1 class="m-2 text-center">{{firma.Headline}}<br />
                                                <small class="y-2">{{firma.Subline}}</small>
                                            </h1>
                                        </div>
                                        <div class="mt-4 ">
                                            <h2 class="pt-2 text-center">
                                               {{firma.Content}}
                                            </h2>
                                        </div>
                                        <div>
                                            <a class="btn btn-primary btn-sm d-none" :href="'http://' +firma.url" target="_blank" role="button"> <i class="fas fa-link"></i> Zur Homepage</a>
                                        </div>
                                </div>
                            </div>
                        </div>

                </div>
            </div>
            <a class="carousel-control-prev" href="#kundenImFocus" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#kundenImFocus" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>

    </div>



    <div class="row mx-0">
        <div v-for="firma in werbeFirmen" class="card mb-3 imfokus-box col-md-4 m-0 border-5 border-white border-top-0 border-bottom-0">
            <a :href="'' + firma.Link" target="_blank" :title=  firma.Headline><img class="d-block w-100 mb-0"
                                                                                    :src="'/storage/images/content/'+ firma.Image"
                                                                                    v-on:click="sethitimfocusbanner(firma.KID, 5, 0 ,'Klicks')"
                                                                                    :alt= firma.Headline
                                                                                    :title=  firma.Headline
            >
            </a>
            <div style="height:16rem;" class="card-body border-none">
                <h1 class="text-center">{{firma.Headline}}<br />
                <small class="text-center">{{firma.Subline}}</small></h1>
                <hr style="background-color: white; width: 100%;" class="m-0 p-0" />
                <p class="card-text text-center mt-3">{{firma.Content}}</p>
            </div>
            <div class="card-footer text-center -border-none">
                <small class="text-muted">
                    <a class="btn btn-primary btn-sm " :href="firma.Link" target="_blank" role="button"
                                              v-on:click="sethitimfocusbanner(firma.KID, 5, 0 ,'Klicks')"
                                              :alt= firma.Headline
                                              :title=  firma.Headline
                > <i class="fas fa-link"></i> Zur Homepage</a>
                </small>
            </div>
        </div>
    </div>


</div>
</template>

<script>
    export default {
        //name: 'banner-widget',
        //props: ['banner'],
        data() {
            return {
                werbeFirmen: [],
            };
        },

        methods: {
            getBanner() {
                axios.get('/banner/getImFocusBanner', {})
                    .then(response => {
                        this.werbeFirmen = response.data
                        this.setCounterView(response.data)
                       // console.log(this.werbeFirmen);
                    })
            },

            sethitimfocusbanner: function(KID,typID,flag,typ) {
              var ADID;
                var bannerKlicks;
                axios.get('/banner/getADID/' + KID + '/' + typID , {})
                    .then(response => {
                        this.ADID = response.data
                        //liegt im modul statistik/web
                        axios.get('/statistik/firma/setBannerHitsKlicks/' + this.ADID + '/' + typID + '/' + flag + '/' + typ, {})
                            .then(response => {
                                this.bannerKlicks = response.data
                               // console.log(this.bannerKlicks);
                            });
                    });




            },

            setCounterView(daten){


                var ADID;
                var bannerViews;

                axios.get('/banner/getADID/' + daten[0].KID + '/5' , {})
                    .then(response => {
                        ADID = response.data

                        axios.get('/statistik/firma/setBannerHitsViews/' + ADID , {})
                            .then(response => {
                                bannerViews = response.data

                            });
                    });

            },


            setCounterArrayView(daten){
                var ADID;
                var bannerViews;
                daten.forEach(function(element){
                    axios.get('/banner/getADID/' + element.KID + '/5' , {})
                        .then(response => {
                            ADID = response.data

                            axios.get('/statistik/firma/setBannerHitsViews/' + ADID , {})
                                .then(response => {
                                     bannerViews = response.data
                                     console.log('die BannerViews sind', bannerViews);
                                });
                        });
                    }
                );
            }

        },

        mounted() {
            this.getBanner();
        }
    }
</script>

<style scoped>

.border-5 {
    border-width:5px !important;
    border-color: white;
}

.card {
    box-shadow: none !important;
    margin-bottom: 0rem;
}
</style>
