
<!--- PremiumKunden Helper von Dieter in parent vue einbauen parameter überbeben
<premium-kunden :banner="  premiumku  " ></premium-kunden>
-->

<template>
<div>
    <div v-for="firma in werbeFirmen" :class="" >
        <a :href="'' + firma.url" target="_blank" :title=  firma.url>
            <img class="d-block w-100 mb-2"
             :src="'/storage/partnergfx/'+ firma.bildname"
             v-on:click="sethitfullbanner(firma.KID, 1, -1 ,'Klicks')"
             alt='Bild Fullbanner'
             :title=  firma.url
        >
            </a>

    </div>
</div>
</template>

<script>
    export default {
        //name: 'banner-widget',
        //props: ['banner'],
        data() {
            return {
                werbeFirmen: [],
            };
        },

        methods: {
            getBannerRechts() {
                axios.get('/firma/vue/getFullbanner', {})
                    .then(response => {
                        this.werbeFirmen = response.data
                       // console.log(this.werbeFirmen);
                        this.setFullbannerImpressionen(response.data);
                    })
            },

            sethitfullbanner: function(KID,typID,flag,typ) {
                var ADID;
                var bannerKlicks;
                axios.get('/banner/getADID/' + KID + '/' + typID , {})
                    .then(response => {
                        this.ADID = response.data
                        //liegt im modul statistik/web
                        axios.get('/statistik/firma/setBannerHitsKlicks/' + this.ADID + '/' + typID + '/' + flag + '/' + typ, {})
                            .then(response => {
                                this.bannerKlicks = response.data
                                //console.log(this.bannerKlicks);
                            });
                    });
            },

            setFullbannerImpressionen(daten){
               // console.log('fullbanner wurde eingeblendet',daten);
                daten.forEach(function(item){
                    ////banner/fullbanner/setImpressum/{id}/{blid}/{typ}
                     axios.get('/banner/fullbanner/setImpressum/' + item.bannerlistID + '/-1/Views' , {})
                        .then(response => {
                            //this.werbeFirmen = response.data
                            // console.log(response.data);
                        })

                });
            },


        },


        mounted() {
            this.getBannerRechts();
        }
    }
</script>
