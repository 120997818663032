<template>
    <div>
        <form action="/startseite/bauangebote/schritt2/branchen" method="post">
            <input type="hidden" id="angeboteVonStartseite" name="angeboteVonStartseite" value="ja">
            <input type="hidden" name="_token" id="token" :value=" csrf ">

        <div class="form-row">
            <div class="form-group w-100"><label>Bundesland</label>
                <select required class="custom-select text-left" name="Bundesland" v-model="bundesland" id="mylocations-bundesland">
                    <option value="0" disable="true" selected="true">wählen</option>
                    <option v-for="option in bundesland_options" v-bind:value="option.BLID">
                        {{ option.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group w-100"><label for="">Bezirk</label>
                <select required class="custom-select text-left" name="Bezirk" id="mylocations-bezirke" v-model="bezirke">
                    <option value="0" disable="true" selected="true">wählen</option>
                    <option v-for="option in bezirke_options[bundesland]" :value="option.IDBEZ" :key="option.IDBEZ">
                        {{ option.BEZIRK }}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group w-100">
                <button type="submit" class="btn btn-success">Suche</button>
            </div>
        </div>
        </form>

    </div>
</template>

<script>

    var bundesland_options = [];

    var bezirke_options = {};


    export default {
        //name: "chainselect",  // using EXACTLY this name is essential
        name: "mylocations2",
        data() {
            return {
                bundesland_options: bundesland_options,
                bezirke_options: bezirke_options,
                csrf: document.head.querySelector('meta[name="csrf-token"]').content,
            }
        },

        props: {
            bundesland: {
                type: String,
                default: '0'
            },
            bezirke: {
                type: String,
                default: '0'
            },

        },


        // props: ['bundesland','bezirke','gemeinde','orte','plz'],
        watch: {},

        methods: {
            loadBundesland() {
                axios.get("/mylocations/vue/bundesland").then(response => this.bundesland_options = response.data);
            },
            loadBezirke() {
                axios.get("/mylocations/vue/bezirke").then(response => this.bezirke_options = response.data);

            },

        },
        created() {
            this.loadBundesland();
            this.loadBezirke();
            console.log('bin in MyLocations2')

        }
    }


</script>

<style scoped>

</style>
