<template>
    <div>
        <!--
         <div class="container-fluid mt-5 ">
             <div class="row">
                 <div class="col-md-12">
                     <div class="row text-left">
                         <div v-for="item in items" :key="item.id">
                             <div class="col-md-3 col-lg-4">{{ item.id }} </div>
                             <div class="col-md-3 col-lg-4">{{ item.name }} </div>

                         </div>
                     </div>
                 </div>
             </div>
         </div>
         -->

        <div v-if="showList && absenden == 'ja'" class="firmen results" >
            <!-- Branchen Banner bzw. Nationalbanner -->
            <div v-if="Object.keys(branchenBanner).length > 0 && branchenBannerAnzeigen == true && prembanner.length == 0
        && this.anzahlBLDSEvent == 0 && this.anzahlBZDSEvent == 0
" class="mb-5">
                <p class="text-right text-muted border-bottom mt-5">National Banner</p>
                <div class="position-relative " >
                    <div class="d-none ribbon-wrapper ribbon-xl">
                        <div class="ribbon bg-danger">
                            National Banner
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="row">
                            <div :id="'ankerpremtel-' + item.firma_id" :name="'isTelefonNr' + item.firma_id"  v-for="(item, index) in branchenBanner" :key="item.firma_id" class="col-sm-6 col-md-6 col-lg-6 p-0 m-0 mb-2">
                                <div class="position-relative p-3 mx-1 text-white bg-gray" style="height: 160px">
                                    <div class="ribbon-wrapper ribbon-lg .ribbon">
                                        <div class="ribbon bg-info">
                                            Nationalbanner
                                        </div>
                                    </div>
                                    <div class="media w-75">

                                        <div style="cursor: pointer" :title="item.firma_name" :alt="item.slug" target="_blank" @click="setHitKlicksLaravel('KlicksPrem',item.firma_id, item.slug, item.web)"><img :src="'/storage/logos/' + item.cover_image" :title="item.firma_name"  class="align-self-start mr-3" :alt="item.firma_name"></div>
                                        <div class="media-body">
                                            <h5 class="mt-0 m1-5">{{item.firma_name}}</h5>
                                            <!-- <a class="mr-3 text-white" :href="'#'" v-if="isTelefonNr.id !== item.firmaID" @click="zeigeTelefonNr(item.firmaID),setHitKlicks('telclick',item.firmaID)"><i class="fas fa-phone-alt"></i> Anzeigen</a>
                                             <a class="mr-3 text-white" :href="'#'" v-if="isTelefonNr.id === item.firmaID"><i class="fas fa-phone-alt"></i>&nbsp;{{item.tel}}</a><br /> -->
                                            <div style="cursor: pointer" class="text-white mt-1" :title="item.slug" target="_blank" @click="setHitKlicksLaravel('KlicksPrem',item.firma_id,item.slug, item.web)">Mehr Info <i class="fas fa-info-circle"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>



            <!--  <PremiumBannerNationalWidget :mysuchmuster="suchmuster"></PremiumBannerNationalWidget> -->

            <div v-if="prembanner.length > 0 && zeigePRKU == true" class="mb-5">
                <p class="text-right text-muted border-bottom mt-5">Prämiumkunden</p>
                <div class="position-relative " >
                    <div class="d-none ribbon-wrapper ribbon-xl">
                        <div class="ribbon bg-danger">
                            Premium Kunden
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="row">
                            <div :id="'ankerpremtel-' + item.firmaID" :name="'isTelefonNr' + item.firmaID" v-for="item in prembanner" class="col-sm-6 col-md-6 col-lg-6 p-0 m-0 mb-2">
                                <div class="position-relative p-3 mx-1 text-white bg-gray" style="height: 160px">
                                    <div class="ribbon-wrapper ribbon-lg .ribbon">
                                        <div class="ribbon bg-info">
                                            Premiumkunde
                                        </div>
                                    </div>
                                    <div class="media w-75">

                                        <div style="cursor: pointer" :title="item.firmaname" :alt="item.slug" target="_blank" @click="setHitKlicksLaravel('KlicksPrem',item.firmaID,item.slug, item.web)"><img :src="'/storage/logos/' + item.cover_image" :title="item.firmaID"  class="align-self-start mr-3" alt="item.firmaname"></div>
                                        <div class="media-body">
                                            <h5 class="mt-0 m1-5">{{item.firmaname}}</h5>
                                            <!-- <a class="mr-3 text-white" :href="'#'" v-if="isTelefonNr.id !== item.firmaID" @click="zeigeTelefonNr(item.firmaID),setHitKlicks('telclick',item.firmaID)"><i class="fas fa-phone-alt"></i> Anzeigen</a>
                                             <a class="mr-3 text-white" :href="'#'" v-if="isTelefonNr.id === item.firmaID"><i class="fas fa-phone-alt"></i>&nbsp;{{item.tel}}</a><br /> -->
                                            <div style="cursor: pointer" class="text-white mt-1" :title="item.slug" target="_blank" @click="setHitKlicksLaravel('KlicksPrem',item.firmaID,item.slug, item.web)">Mehr Info <i class="fas fa-info-circle"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>



            <div class="my-4 clearfix">
                <div class="row">
                    <div class="col">
                        <h5 class="">{{ anzahlEintrag }} Treffer mit Suchbegriff: <strong>{{suchmuster}}</strong></h5>
                    </div>
                    <div class="col-md-auto text-right">
                        <a style="color:green;" href="/"><i class="fas fa-search"></i> Neue Suche</a>
                    </div>
                </div>

            </div>






            <div :id="'firmenID-' + item.id" v-for="(item,index) in items" :key="item.id" v-if="index !== 'premiumkunden'" class="media border-bottom my-2 py-2">
                <!-- <div style="cursor: pointer" :href="'/'+ item.slug" :title="'Firma ' + item.name + ' - Branche: ' + item.branchen" @click="setHitKlicks2('Klicks',item.id,item.slug)"><img :src="'/storage/logos/' + item.cover_image" class="align-self-center mr-3  " :alt=" item.name "></div> -->
                <!--  <router-link style="cursor: pointer" :to="'/firmadetail/seite/' + item.slug" :title="'Firma ' + item.name + ' - Branche: ' + item.branchen" @click="setHitKlicks2('Klicks',item.id,item.slug)"><img :src="'/storage/logos/' + item.cover_image" class="align-self-center mr-3  " :alt=" item.name "></router-link> -->
                <router-link :to="'/firmadetail/seite/' + item.slug">
                    <img style="cursor: pointer" @click="switchPage(),setHitKlicks2Vue('Klicks',item.slug)" :src="'/storage/logos/' + item.cover_image" class="align-self-center mr-3" :title="item.name" :alt="item.name"/>
                </router-link>
                <div class="media-body">
                    <h5 class="">{{ item.name | ucFirst | kurz}}</h5>
                    <p class="bezirkList mb-0">{{ item.Bezirk }}</p>
                    <p v-if="item.vorteilscard > 0 " class="float-left d-none d-sm-block text-muted"> {{item.vorteilscard}}% Vorteilscard&nbsp;</p>
                    <p  v-if="item.gutscheinHeader !== null" class="d-none d-sm-block float-left text-muted">|&nbsp;Gutschein verfügbar </p>
                    <span class="mehrInfo">

                       <div style="cursor: pointer" class="d-none mybtn btn-default" :href="'/'+ item.slug" :title="item.branchen + ' - ' + item.id" @click="setHitKlicks2('Klicks',item.id,item.slug)" role="button"><i class="fas fa-info-circle fa-lg" ></i>  mehr info...</div>

                       <div>
                            <router-link :to="'/firmadetail/seite/' + item.slug" :title="item.branchen + ' - ' + item.id">
                                 <span style="cursor: pointer" class="mybtn btn-default" @click="switchPage(),setHitKlicks2Vue('Klicks',item.slug),showStartseiteSeo(false)"  role="button"><i class="fas fa-info-circle fa-lg" ></i>  mehr info...</span>
                            </router-link>
                       </div>
                    </span>
                    <!-- <a class="mybtn btn-default" :href="'/'+ item.slug" :title="item.branchen" @click="setHitKlicks2('Klicks',item.id)" role="button"><i class="fas fa-info-circle fa-lg" ></i>  mehr information...</a> -->

                </div>
            </div>



            <div  v-if="items.length == 0" class="w-auto text-center mb-3">
                <div  class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Suche...</span>
                </div>
                <span class="d-block mt-2 ">Suche</span>
            </div>
            <div v-if="gefunden == 'nein'">
                <div class="alert alert-warning" role="alert">
                    Es gibt keinen Eintrag im Bezirk für diese Branche!
                </div>
            </div>


            <div v-if="anzahlBLDSEvent > 0 && anzahlBZDSEvent > 0">
                <BezirkBannerWidget :mysuchmuster="mysuchmuster"></BezirkBannerWidget>
            </div>
            <div v-else-if="anzahlBLDSEvent > 0">
                <BezirkBannerWidget :mysuchmuster="mysuchmuster"></BezirkBannerWidget>
                <BundeslandBannerWidget :mysuchmuster="mysuchmuster"></BundeslandBannerWidget>
            </div>
            <div v-else>
                <BundeslandBannerWidget :mysuchmuster="mysuchmuster"></BundeslandBannerWidget>
                <BezirkBannerWidget :mysuchmuster="mysuchmuster"></BezirkBannerWidget>
            </div>


        </div>
        <div  v-if="showDetailSeite">
            <router-view></router-view>
        </div>

    </div>
</template>

<script>


import BundeslandBannerWidget from "../banner/BundeslandBannerWidget";
import BezirkBannerWidget from "../banner/BezirkBannerWidget";
import PremiumBannerNationalWidget from "../banner/PremiumBannerNationalWidget";
import keinTreffer from "./helper/keinTreffer";

var moment = require('moment');

export default {
    components:{BundeslandBannerWidget,BezirkBannerWidget,PremiumBannerNationalWidget,keinTreffer},
    props: {
        suchmuster:{
            type: String
        },
        absendenProps:{
            type: String
        },
    },

    data () {
        return {
            absenden: this.absendenProps,

            showList: true,
            showDetailSeite: false,

            anzahlEintrag: 0,
            success: false,
            error: false,
            message: '',
            output: '',
            moment: moment,
            gefunden: 'ja',
            myBLID: 0,
            myBRID: 0,
            items : [],
            prembanner: [],
            mysuchmuster: this.suchmuster,
            isTelefonNr: {
                id: false,
            },
            zeigePRKU: false,
            anzahlBLDSEvent: 0,
            anzahlBZDSEvent: 0,

            branchenBannerAnzeigen: false,
            branchenBanner: [],

        }
    },
    watch: {

    },

    computed: {


        suchEnde: function(){
            setTimeout(function(){ // startet in 10Sekunden
                this.gefunden = 'nein';

            }, 0);
        }


    },



    methods: {

        showStartseiteSeo(status){
            Fire.$emit('show-startseite-content',status);
        },


        goBack(schritte){
            this.$router.go(schritte);
        },


        switchPage(){
            this.showList = false;
            this.showDetailSeite = true;

            const daten = [];
            daten['showSuchmaske'] = false;
            Fire.$emit('showSuchmaskeEmit',daten);
        },

        resetBrancheNationalBanner(){
            this.branchenBanner = [];
        },
        getBrancheNationalBanner(id){

            axios.get('/banner/brancheNational/list/' + id)
                .then((response) => {

                    this.branchenBanner = response.data;

                    if(Object.keys(this.branchenBanner).length > 0 && this.anzahlBLDSEvent == 0 && this.anzahlBZDSEvent == 0) {
                        this.branchenBannerAnzeigen = true;
                    }
                })
                .catch(() => {
                    //this.$Progress.fail();
                })
        },

        /*
         setHitWeblink(type,slug){
             axios.get('/statistik/firma/setKlicks/'+ type + '/' + slug).then('OK');
         },
         setHitVkKlicks(type,slug){
             axios.get('/statistik/firma/setKlicks/'+ type + '/' + slug).then('OK');
         },


         setHitKlicks(type,slug){
             axios.get('/statistik/firma/setKlicks/'+ type + '/' + slug).then('OK');
         },
         */

        setHitKlicks(type,slug){
            let currentObj = this;

            console.log('type ',type,' und slug ',slug);

            // axios.get('/statistik/firma/setKlicks/'+ type + '/' + slug).then('OK');
            axios.get('/statistik/firma/setKlicks/'+ type + '/' + slug).then(({response}) => (
                console.log(response),
                    currentObj.success = response.succes
            )).catch(function (error) {
                currentObj.error = error
            });
        },


        // nur das geht am Server in Wien
        setHitKlicks2(type,id,slug){
            let currentObj = this;
            // window.alert('type ' + type + ' und id ' + id + ' und slug ' + slug);
            // console.log('type ' + type + ' und id ' + id + ' und slug ' + slug);

            //link alt  var root = location.protocol + '//' + location.host + '/';
            var root = location.protocol + '//' + location.host + '/' + 'firmadetail/seite/';




            // axios.get('/statistik/firma/setKlicks/'+ type + '/' + slug).then('OK');
            axios.get('/statistik/firma/setKlickszwei/'+ type + '/' + id + '/' + slug).then(({response}) => (

                console.log(response),
                    currentObj.success = response.success
            )).catch(function (error) {
                currentObj.error = error
            });
            //currentObj.$router.push(root + slug.toLowerCase());
            // geht nicht weil keine vue weiterleitung window.location.replace(root + slug.toLowerCase())
            this.$router.push(root + slug.toLowerCase());
        },





        // nur das geht am Server in Wien
        setHitKlicks2Vue(type,slug){
            let currentObj = this;
            // window.alert('type ' + type + ' und id ' + id + ' und slug ' + slug);
            // console.log('type ' + type + ' und id ' + id + ' und slug ' + slug);

            //link alt  var root = location.protocol + '//' + location.host + '/';
            var root = location.protocol + '//' + location.host + '/' + 'firmadetail/seite/';




            // axios.get('/statistik/firma/setKlicks/'+ type + '/' + slug).then('OK');
            axios.get('/statistik/firma/setKlickszweiVue/'+ type + '/' + slug).then(({response}) => (


                currentObj.message = response.message
            )).catch(function (error) {
                // currentObj.error = error
            });
            //currentObj.$router.push(root + slug.toLowerCase());
            // geht nicht weil keine vue weiterleitung window.location.replace(root + slug.toLowerCase())

            // window.alert(root + slug.toLowerCase());
            this.$router.push(root + slug.toLowerCase());
        },


        // Laravel nur das geht am Server in Wien
        setHitKlicksLaravel(type,id,slug, web){
            let currentObj = this;
            // window.alert('type ' + type + ' und id ' + id + ' und slug ' + slug);
            // console.log('type ' + type + ' und id ' + id + ' und slug ' + slug);

            var root = location.protocol + '//' + location.host + '/';
            var webroot = web;
            //var root = location.protocol + '//' + location.host + '/' + 'firmadetail/seite/';




            // axios.get('/statistik/firma/setKlicks/'+ type + '/' + slug).then('OK');
            axios.get('/statistik/firma/setKlickszwei/'+ type + '/' + id + '/' + slug).then(({response}) => (

                // console.log(response),
                currentObj.success = response.success
            )).catch(function (error) {
                currentObj.error = error
            });
            //currentObj.$router.push(root + slug.toLowerCase());
            //window.location.replace(root + slug.toLowerCase())

            // geht und zeigt auf Visitenkarte
            // window.location.href = root + slug.toLowerCase();

            //auf Webseite verlinkt
            //window.location.href = webroot;

            window.open(
                webroot,
                '_blank' // <- This is what makes it open in a new window.
            );

            //this.$router.push(root + slug.toLowerCase());
        },



        setEinblendungPremiumBanner(){
            //console.log('bin in setEinblendungPremiumBanner',this.prembanner.length);
            if(this.prembanner.length > 0) {
                this.prembanner.forEach(function(item){
                    axios.get('/statistik/firma/setTabLinkKlicks/' + item.firmaID + '/ViewsPrem').then('OK');
                    //console.log('der wert = ',item.firmaID);
                });
            }
        },

        setEinblendungItems(wert){
            var result = Object.keys(wert).map(function(key) {
                //return  wert[key].id;
                if(wert[key].id != undefined) {
                    axios.get('/statistik/firma/setTabLinkKlicks/' + wert[key].id + '/ViewsBot').then('OK');
                }
            });
            // console.log(result);

            /*


            wert.forEach(function(item){
                    console.log('die ITems sind: ',item.id);
                    axios.get('/statistik/firma/setTabLinkKlicks/' + item.id + '/ViewsBot').then('OK');
                    //console.log('der wert = ',item.firmaID);
                });
*/


        },


        loadFirmen(suchmuster){



            this.$Progress.start();

            this.resetBrancheNationalBanner();

            axios.get('/firma/suchfilter/vue/getIds/1/0/' + suchmuster)
                .then((response) => {
                    this.items = response.data;
                    this.getBrancheNationalBanner(this.items.premiumkunden['BRID']);
                    this.anzahlEintrag = Object.keys(this.items).length - 1;
                    if(Object.keys(this.items).length == 1){
                        this.gefunden = 'nein';

                    }
                    else{
                        this.gefunden = 'ja';
                    }

                    this.setEinblendungItems(this.items)

                    this.$Progress.finish();
                    // this.premiumkunden = data.data.pop();
                    this.myBLID = this.items.premiumkunden['BLID'];
                    this.myBRID = this.items.premiumkunden['BRID'];



                    this.getPremBanner(this.myBLID,this.myBRID);
                })
                .catch(() => {
                    //this.$Progress.fail();
                })
        },


        hasBundesland(value){

            value = value.toLowerCase();
            let bundeslander = ['steiermark','wien','burgenland','kärnten','vorarlberg','niederösterreich','oberösterreich','tirol','salzburg'];
            var status = false;
            if(bundeslander.length > 0){

                bundeslander.forEach(function(bundesland,i){
                    bundesland = bundesland.toLowerCase();
                    if(value.search(bundesland)!== -1) {
                        status = true;
                    }

                });
                this.zeigePRKU = status;
                if(this.zeigePRKU== false){
                    this.prembanner = []
                }

            }
            //let result = value.search (/Steiermark/i);
        },

        getPremBanner(BLID,BRID) {
            if(this.zeigePRKU == true) {
                axios.get('/firmen/getPremiumkundenBundesland/' + BLID + '/' + BRID, {})
                    .then(response => {
                        this.prembanner = response.data
                        this.setEinblendungPremiumBanner();
                        //console.log(this.werbeFirmen);
                    })
            }
        },

        zeigeTelefonNr(id){
            this.isTelefonNr.id =  id;
        },

        entferneSlash(suchmuster){
            if(suchmuster.search("/") !== -1 || suchmuster.search(" in ") !== -1) {

                suchmuster =  suchmuster.replace(" in ", " ")
                suchmuster =  suchmuster.replace("/", " ")

                console.log('der / wurde enfernt: ',suchmuster);
                return suchmuster;
            }
            return suchmuster;
        },



    },




    filters: {
        kurz: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.substr(0, 80);
        },

        ucFirst(value) {
            if (!value) return ''
            value = value.toString()
            return value.substring(0, 1).toUpperCase() + value.substring(1);
        },

        zeichenAnzahl(value){
            if (!value) return ''
            value = value.toString();
            let anzahl = 0;
            anzahl = value.length;
            return anzahl;
        }
    },


    created() {



        this.loadFirmen(this.entferneSlash(this.suchmuster));

        //   this.suchEnde2();


        this.hasBundesland(this.suchmuster);
        //this.mysuchmuster = this.suchmuster;
        Fire.$on('searching',() => {
            this.loadFirmen(this.entferneSlash(this.suchmuster));
            this.hasBundesland(this.suchmuster);
            //this.getPremBanner(this.myBLID,this.myBRID);
        }),

            Fire.$on('anzahlBLDSEvent',(data) => {
                this.anzahlBLDSEvent = data;
            }),

            Fire.$on('anzahlBZDSEvent',(data) => {
                this.anzahlBZDSEvent = data;
            })
    },



    mounted() {



        Fire.$on('showSuchtrefferEmit',(data) => {
            console.log('emit data => ', data);
            console.log('zeige die Emit ShowSuchtrefferEmit showlist => ', data.showList);
            console.log('zeige die Emit ShowSuchtrefferEmit showDetailSeite', data.showDetailSeite);
            this.showList = data.showList,
                this.showDetailSeite = data.showDetailSeite
        });








        console.log('bin in suchfilter Results.vue');
    }
}
</script>
